/**
 * After login - grab enrolled course and forward to lms
 */
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Message, useGlobal} from "up-form";
import {useEnrolments} from "up-state";
import {selectUser} from "../../state/appReducers";

export const FirstLogin = () => {
  const {studentId} = useSelector(selectUser) || {};

  const {
    data,
    data: [{courseCode} = {}] = [],
    pending,
    error
  } = useEnrolments(studentId);

  const {t} = useTranslation();
  const {
    links: {lmsCourse}
  } = useGlobal();

  useEffect(() => {
    if (courseCode && studentId) {
      /**
       * Enrolment found - just redirect
       */
      const redirect = `${lmsCourse}/?course=${courseCode}&upId=${studentId}`;
      console.log(`First login, redirecting to ${redirect}`);
      window.location.href = redirect;
    }
  }, [courseCode, lmsCourse, studentId]);

  return (
    <>
      <Message open={pending} variant="busy" message={t("FirstLogin.busy")} />
      <Message
        open={error || (data && !courseCode)}
        variant="error"
        message={t("FirstLogin.error")}
      />
    </>
  );
};

export default FirstLogin;
