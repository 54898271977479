import React from 'react';
import pure from 'recompose/pure';
import {SvgIcon} from '@material-ui/core';

const SignIn = pure((props) => (
  <SvgIcon viewBox="0 0 20 20"  xmlnsXlink="http://www.w3.org/1999/xlink" {...props} >
    <title>icon - Arrow Down Copy 5</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <circle id="path-1" cx="8" cy="8" r="8"></circle>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-List-View---Logged-Out" transform="translate(-947.000000, -66.000000)">
            <g id="Group-2-Copy-2" transform="translate(857.000000, 56.000000)">
                <g id="icon---Arrow-Down-Copy-11" transform="translate(88.000000, 8.000000)">
                    <g id="Group" transform="translate(3.000000, 3.000000)">
         
                        <g id="Oval-Copy-2" transform="translate(1.000000, 2.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="Mask"></g>
                            <circle stroke="currentColor" strokeWidth="2" mask="url(#mask-2)" cx="8" cy="17" r="8"></circle>
                        </g>
                        <circle id="Oval-Copy" stroke="currentColor" strokeWidth="2" cx="9" cy="7" r="3"></circle>
                        <circle id="Oval-Copy-2" stroke="currentColor" strokeWidth="2" cx="9" cy="9" r="9"></circle>
                    </g>
                </g>
            </g>
        </g>
    </g>
</SvgIcon>
));
SignIn.displayName = 'SignIn';     
SignIn.muiName = 'SvgIcon';
export default SignIn;