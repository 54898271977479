/**
 * Custom images developed in the Up LMS project use a srcsets for predefined breakpoints. This geneerates the
 * html scaffolding for the asset set
 *
 */
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  root: {},
  image: {}
});

const UpPicture = ({
  classes,
  uri,
  alt,
  breakpoints = {lge: "(min-width: 48.0625em)", med: "(min-width: 30.0625em)", sml: null},
  resolutions = ["1x", "2x", "3x"],
  defaultResolution = resolutions[0],
  defaultSize = (Object.entries(breakpoints).find(([, media]) => !media) || [Object.keys(breakpoints)[0]])[0],
  mapUri = (uri, size, resolution) =>
    `${uri}-${size}${(resolution && resolution !== defaultResolution && `-${resolution}`) || ""}.jpg${
      (resolution && ` ${resolution}`) || ""
    }`
}) => {
  return (
    <picture className={classes.root}>
      {Object.entries(breakpoints).map(([size, media], i) =>
        media ? (
          <source key={i} media={media} srcSet={resolutions.map((resolution) => mapUri(uri, size, resolution)).join(",")} />
        ) : (
          <img
            alt={alt}
            className={classes.image}
            key={i}
            src={mapUri(uri, defaultSize)}
            srcSet={resolutions
              .filter((resolution) => resolution !== defaultResolution)
              .map((resolution) => mapUri(uri, size, resolution))
              .join(",")}
          />
        )
      )}
    </picture>
  );
};

UpPicture.propTypes = {
  alt: PropTypes.any.isRequired,
  breakpoints: PropTypes.object, // key is breakpoint name, value is media selector
  classes: PropTypes.shape({
    image: PropTypes.any,
    root: PropTypes.any
  }),
  defaultResolution: PropTypes.string, //
  defaultSize: PropTypes.any,
  mapUri: PropTypes.func, // (uri,size,resolution)=>string - map the arguments to a src/srcset component (reslution will be null for img src)
  resolutions: PropTypes.array, // List of resolutions avaliable for asset group
  uri: PropTypes.any
};

export default withStyles(styles, {name: "UpPicture"})(UpPicture);
