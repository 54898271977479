import {Chip, makeStyles, Typography} from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import SimpleTable from "../table/SimpleTable";
import ResultChip from "./ResultChip";

const useStyles = makeStyles((theme) => ({
  row: {},
  cell: {}
}));

const Grades = ({enrolments}) => {
  const {t} = useTranslation();
  const {
    component: {MyEnrolmentsPage: {dateFormat = "LL"} = {}} = {},
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } = useGlobal();
  const classes = useStyles();
  function toTimezonedDate(v) {
    return v ? moment.tz(v, timezone) : undefined;
  }

  // Flatten into a single list of academicRecords outer joined with course,block, module
  // Filter out no valid records etc and fix up API inconsistencies
  const grades =
    enrolments &&
    enrolments
      .flatMap((course) =>
        (course.enrolmentBlockList || []).flatMap((block) =>
          (block.enrolmentModuleList || []).flatMap((module) =>
            (module.academicRecords || []).map((academicRecord) => {
              const {gradedDate, statusCodeLabel} = academicRecord;
              return {
                course,
                block,
                module,
                academicRecord: {
                  ...academicRecord,
                  gradedDate: toTimezonedDate(gradedDate),
                  // Hack here for label not always what they want displayed (note replace in data here so sort works!)
                  statusCodeLabel: {"Pending Ratification": "Provisional"}[statusCodeLabel] || statusCodeLabel
                }
              };
            })
          )
        )
      )
      .filter(({academicRecord: {statusCodeLabel} = {}}) => /Ratified|Provisional/.test(statusCodeLabel));
  function gradeRow({module: {name: moduleName, moduleCode}, academicRecord}) {
    const {name, statusCodeLabel, gradedDate, externalGrade, resultText} = academicRecord;
    return [
      <Typography className={classes.moduleCode}>{moduleCode}</Typography>,
      <Typography className={classes.gradeName}>{name}</Typography>,
      <Typography className={classes.gradeDate}>{gradedDate && gradedDate.format(dateFormat)}</Typography>,
      resultText && <ResultChip externalGrade={externalGrade} resultText={resultText} />,
      <Chip color="primary" label={statusCodeLabel}></Chip>
    ];
  }
  return (
    <SimpleTable
      classes={{cell: classes.cell, row: classes.row}}
      defaultSortColumn="Grade Date"
      itemName="Grade"
      emptyLabel={t("MyEnrolmentsPage.grades.noGrades")}
      rows={grades}
      renderHeaderRow={() => ["Subject", "Name", "Grade Date", "Grade", "Status"]}
      renderRow={gradeRow}
      //getCellProps={(column) => ({align: column > 0 ? "right" : "left"})}
      sort={{
        Subject: ({module: {name: a}}, {module: {name: b}}) => (a || "").localeCompare(b || ""),
        Name: ({academicRecord: {name: a}}, {academicRecord: {name: b}}) => (a || "").localeCompare(b || ""),
        "Grade Date": ({academicRecord: {gradedDate: a}}, {academicRecord: {gradedDate: b}}) => (a || 0) - (b || 0),
        Grade: ({academicRecord: {resultText: a}}, {academicRecord: {resultText: b}}) => (a || "0").localeCompare(b || "0"),
        Status: ({academicRecord: {statusCodeLabel: a}}, {academicRecord: {statusCodeLabel: b}}) => (a || "").localeCompare(b || "")
      }}
    />
  );
};

export default Grades;
