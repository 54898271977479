import {Box} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {ApiBusy, useGlobal} from "up-form";
import LmsMenu from "../navigation/LmsMenu";
import Login from "../user/Login";
import SignInButton from "../user/SignInButton";
import AccountButton from "./AccountButton";
import {BurgerMenu} from "./BurgerMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    //marginBottom: '1em',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiButtonBase-root, & .MuiTypography-root": {
      fontSize: "12px !important"
    }
  },
  colorPrimary: {
    color: theme.palette.getContrastText(theme.palette.background.paper)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textTransform: "uppercase"
  },
  logo: ({logoBg}) => ({
    maxHeight: "3.5em",
    margin: ".25em",
    backgroundColor: logoBg || theme.palette.background.default
  })
}));

const StudentPortalAppBar = React.forwardRef((props, ref) => {
  const {
    page: {name},
    favicon,
    logo,
    logoBg,
    site
  } = useGlobal();
  const classes = useStyles({logoBg});
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  useEffect(() => {
    document.title = name;
    const faviconEl = document.getElementById("favicon");
    if (faviconEl && logo) {
      faviconEl.href = favicon || logo;
    }
  }, [name, logo, favicon]);

  return (
    <AppBar
      color="primary"
      classes={{root: classes.root, colorPrimary: classes.colorPrimary}}
      position="sticky"
      elevation={1}
      ref={ref}
    >
      <ApiBusy />
      <Login
        loggedIn={(profile) => (
          <>
            <Toolbar>
              <LmsMenu />
              <Box flexGrow={1} />
              <AccountButton />
            </Toolbar>
            <BurgerMenu
              open={navDrawerOpen}
              onClose={() => setNavDrawerOpen(false)}
              profile={profile}
            ></BurgerMenu>
          </>
        )}
        anonymous={() => (
          <Toolbar>
            {logo && <img className={classes.logo} src={logo} alt={site} />}
            <Typography variant="h6" align="center" className={classes.title}>
              {name}
            </Typography>
            <SignInButton />
          </Toolbar>
        )}
      />
    </AppBar>
  );
});

export default StudentPortalAppBar;
