import {useGlobal} from "up-form";
import ContentPage from "../content/ContentPage";
import PolicyContentNzma from "./nzma/PolicyContent";

export default function PolicyPage() {
  const {slug} = useGlobal();
  const PolicyContent = {
    // both use the same policy content
    "nz-institute-of-sport": PolicyContentNzma,
    nzma: PolicyContentNzma
  }[slug];

  return <ContentPage p={1}>{PolicyContent && <PolicyContent />}</ContentPage>;
}
