/**
 * Configuration by exports values per environment, as set by REACT_APP_STAGE
 */

import {isArray, merge, mergeWith, union, isString} from "lodash";
import {loadClientConfig} from "up-form";
export const {name, version} = require("../package.json");
export const stage = process.env.REACT_APP_STAGE || "development";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const publicUrl = process.env.PUBLIC_URL || window.location.origin;
export const clientConfigKey = `config:${name}:${version}`;
// Allow build-time override from variable
const upApiOverride = process.env.REACT_APP_UP_API_URI;

const data = {
  // Use these values if not provided by stage-specific values
  default: {
    analytics: {
      appName: process.env.REACT_APP_APP_NAME,
      //googleAnalyticsId: "GTM-NK4GLDZ",
      instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
      trackEvents: {
        blacklist: /@@redux-form\/(TOUCH|UNTOUCH|FOCUS|BLUR|(UN)?REGISTER_FIELD)/ // remove some of the highest volume events
      }
    },
    global: {
      buildId,
      branch,
      stage,
      commit,
      isDev,
      options: {
        hostedLogin: true
      },
      component: {
        Optional: {
          // Items are included by default so new features must be excluded
          exclude: [
            "UserMenu.library",
            "UserMenu.myEnrolments",
            "UserMenu.policiesAndProcedures",
            "ProfileForm.general.preferredName",
            "ProfileForm.general.address.readOnly",
            "ProfileForm.general.phone.readOnly",
            "ProfileForm.general.preferredName.readOnly",
            "ProfileForm.general.social.readOnly",
            "ProfileForm.general.state.dropdown",
            "ProfileForm.general.footnote"
          ]
        },
        FilesPage: {
          pageSize: 8,
          recentPeriod: "PT24H"
        },
        MyEnrolmentsPage: {},
        Grades: {
          pageSize: 8,
          recentPeriod: "PT24H"
        },
        Submissions: {
          pageSize: 8,
          recentPeriod: "PT24H"
        },
        Subjects: {
          pageSize: 8,
          recentPeriod: "PT24H"
        },
        PaymentsPage: {
          pageSize: 8,
          recentPeriod: "PT24H"
        },
        SupportPage: {
          caseDescriptionMax: 2000
        },
        ProfileForm: {
          showCourseSectionFirst: false
        }
      },
      google: {
        apiKey: "AIzaSyBdS0kbi8NqHmmUcfFRrNyXgD85ikNuQvo"
      },
      hostMap: {
        soe: "((.*\\.)?soe\\.edu\\.au)|(sut-portal.*\\.uponline\\.education).*",
        iahealth: "((.*\\.)?iah\\.edu\\.au).*",
        ichm: "((.*\\.)?ichm\\.edu\\.au).*",
        "up-online": "(.*\\.)?uponline\\.up\\.education|localhost.*",
        "nz-institute-of-sport": "((.*\\.)?nzis\\.co\\.nz).*",
        nzma: "((.*\\.)?nzma\\.co\\.nz).*",
        yoobee: "((.*\\.)?yoobee\\.ac\\.nz).*",
        nzst: "((.*\\.)?nzschooloftourism\\.co\\.nz).*",
        colab: "((.*\\.)?colab\\.edu\\.au).*"
      },
      links: {
        techSupport: "mailto:onlinesupport@up.education"
      }
    },

    // Merge groups
    providerGroups: {
      default: {
        global: {
          // All providers default to these
          links: {
            paymentContactInfo: {
              phone: "1300 635 480",
              email: "student.services@soe.edu.au"
            },
            forwardLogin: "/profile"
          },
          formOptions: {
            address: {
              autoComplete: true
            }
          }
        }
      },
      // Providers may selectively opt into these
      avetmiss: {
        avetmiss: true
      },
      // Some providers use diffent auth providers, so group them
      aad: {
        msal: {
          config: {
            auth: {
              authority: "https://login.microsoftonline.com/6a425d0d-58f2-4e36-8689-10002b2ec567",
              redirectUri: publicUrl,
              postLogoutRedirectUri: publicUrl + "/signout"
            }
          },
          loginRequest: {
            scopes: ["openid", "email", "profile"]
          },
          tokenRequest: {}
        },
        global: {
          component: {
            Optional: {
              exclude: ["ProfileForm.security"] // can't change password in AAD
            }
          }
        }
      },
      au: {
        // Australian domestic - specific to australian localisation (e.g. selection of states)
        global: {
          component: {
            Optional: {
              exclude: ["ProfileForm.general.state.text"],
              include: ["ProfileForm.general.state.dropdown"]
            }
          }
        }
      },
      cognito: {
        amplify: {
          Auth: {
            region: "ap-southeast-2",
            mandatorySignIn: false,
            oauth: {
              scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
              redirectSignIn: publicUrl,
              redirectSignOut: publicUrl + "/signout",
              responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
          }
        }
      },
      nzmaGroup: {
        global: {
          links: {
            counsellingSupport: "mailto:info@acscounselling.com.au",
            counsellingLink: "https://www.acscounselling.com.au/registration/nzma"
          },
          timezone: "Pacific/Auckland" // Server timezone (be nice if we could ask)
        }
      }
    },
    providers: {
      "up-online": {
        global: {
          timezone: "Pacific/Auckland", // Server timezone (be nice if we could ask)
          groups: ["aad"],
          links: {
            lmsCourse: "https://up-lms{envSuffix}.uponline.education/ext",
            home: "http://up.education",
            terms: "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
          },
          logo: "/providers/up-online/logo.svg",
          welcomeBg: "/providers/up-online/welcomeBg.jpg",
          logoBg: "#FFF"
        },
        msal: {
          config: {
            auth: {
              clientId: "c8da148e-4fdc-4a33-8f5b-8d0a426df13f" // uponline DEV
            }
          }
        }
      },
      iahealth: {
        global: {
          component: {
            ProfileForm: {
              showCourseSectionFirst: true
            },
            Optional: {
              exclude: [
                "ProfileForm.general.about",
                "ProfileForm.general.address",
                "ProfileForm.general.social",
                "UserMenu.support",
                "UserMenu.grades",
                "UserMenu.files",
                "UserMenu.library",
                "UserMenu.payments",
                "UserMenu.policiesAndProcedures"
              ],
              include: [
                "ProfileForm.general.footnote",
                "ProfileForm.general.address.readOnly",
                "ProfileForm.general.phone.readOnly",
                "ProfileForm.general.preferredName.readOnly",
                "ProfileForm.general.name.readOnly",
                "ProfileForm.general.email.readOnly"
              ]
            }
          },
          links: {
            lmsCourse: "https://online{envSuffix}.iah.edu.au",
            studentSupport: "mailto:onlinesupport@up.education"
          },
          groups: ["cognito", "au"],
          timezone: "Australian Eastern Standard Time", // Server timezone (be nice if we could ask)
          favicon: "/providers/iahealth/iah-favicon.svg",
          logo: "/providers/iahealth/iah-logo-w-wordmarkl.svg",
          logoBg: "#FFF",
          welcomeBg: "/providers/iahealth/iah-Background.jpg"
        }
      },
      ichm: {
        global: {
          component: {
            Optional: {
              exclude: [
                "ProfileForm.general.address", // don't show
                "UserMenu.grades",
                "UserMenu.files",
                "UserMenu.payments"
              ],
              include: [
                "ProfileForm.general.footnote",
                "ProfileForm.general.name.readOnly",
                "ProfileForm.general.phone.readOnly",
                "ProfileForm.general.email.readOnly",
                "UserMenu.library",
                "UserMenu.myEnrolments",
                "UserMenu.policiesAndProcedures"
              ]
            }
          },
          links: {
            lmsCourse: "https://icampus{envSuffix}.ichm.edu.au/ext",
            placementPolicy:
              "https://assets.ctfassets.net/s58hoc3j8a24/7aoCFPlxPmC2mKsrK1Dslo/177f512f710f93c1edab4a2efc8c14f4/WIL_Placement_-_Final.pdf",
            policiesAndProcedures: "https://www.ichm.edu.au/about-ichm/student-policies-and-procedures"
          },
          groups: ["aad"],
          timezone: "Pacific/Auckland", // Server timezone (be nice if we could ask)
          favicon: "/providers/ichm/ichm-logo.svg",
          logo: "/providers/ichm/ichm-logo.svg",
          logoBg: "#000070",
          welcomeBg: "/providers/ichm/welcomeBg.svg"
        }
      },
      "nz-institute-of-sport": {
        global: {
          component: {
            Optional: {
              exclude: ["ProfileForm.general.address", "UserMenu.grades", "UserMenu.files", "UserMenu.payments"],
              include: [
                "ProfileForm.general.footnote",
                "ProfileForm.general.address",
                "ProfileForm.general.phone",
                "ProfileForm.general.name.readOnly",
                "ProfileForm.general.email.readOnly",
                "UserMenu.policiesAndProcedures"
              ]
            }
          },
          groups: ["aad", "nzmaGroup"],
          links: {
            lmsCourse: "https://online{envSuffix}.nzis.co.nz",
            studentSupport: "mailto:support@nzis.ac.nz",
            placementPolicy:
              "https://assets.ctfassets.net/s58hoc3j8a24/7aoCFPlxPmC2mKsrK1Dslo/177f512f710f93c1edab4a2efc8c14f4/WIL_Placement_-_Final.pdf",
            handbook:
              "https://assets.ctfassets.net/u1wuyuzf4qv1/6xyZRXipuD2sFG3MCESurz/24f6095d3386243b18cdd53a11277a2d/NZMA_Student_Handbook_2024-February.pdf"  
          },
          favicon: "/providers/nz-institute-of-sport/favicon.ico",
          logo: "/providers/nz-institute-of-sport/logo-institute-of-sports.svg",
          logoBg: "#FFF",
          welcomeBg: "/providers/nz-institute-of-sport/welcomeBg.webp"
        }
      },
      nzma: {
        global: {
          component: {
            Optional: {
              exclude: [
                "ProfileForm.general.about",
                "ProfileForm.general.social",
                "UserMenu.grades",
                "UserMenu.files",
                "UserMenu.library",
                "UserMenu.payments"
              ],
              include: ["UserMenu.policiesAndProcedures"]
            }
          },
          groups: ["aad", "nzmaGroup"],
          links: {
            lmsCourse: "https://online{envSuffix}.nzma.co.nz",
            studentSupport: "mailto:support@nzma.ac.nz",
            handbook:
              "https://assets.ctfassets.net/u1wuyuzf4qv1/6xyZRXipuD2sFG3MCESurz/24f6095d3386243b18cdd53a11277a2d/NZMA_Student_Handbook_2024-February.pdf"
          },
          favicon: "/providers/nzma/favicon32.png",
          logo: "/providers/nzma/nzma-logo.png",
          logoBg: "#FFF",
          welcomeBg: "/providers/nzma/NZMA_Wellington_006.webp"
        }
      },
      soe: {
        global: {
          timezone: "Australia/Melbourne", // Server timezone (be nice if we could ask)
          groups: ["avetmiss", "cognito", "au"],
          links: {
            lmsCourse: "https://app{envSuffix}.soe.edu.au/ext",
            home: "https://www.swinburne.edu.au/",
            terms: "https://www.swinburne.edu.au/privacy/",
            techSupportPolicyLink: "mailto:help@soe.edu.au",
            studentSupportPolicyLink: "https://www.soe.edu.au/policies",
            complaintsPolicyLink: "https://www.soe.edu.au/policies",
            complaintFormLink: "https://www.soe.edu.au/policies",
            completionPolicyLink: "https://www.soe.edu.au/policies",
            assessmentPolicyLink: "https://www.soe.edu.au/policies"
          },
          logo: "/providers/soe/logo.svg",
          welcomeBg: "/providers/soe/welcomeBg.jpg",
          logoBg: "#FFF"
        }
      },
      yoobee: {
        global: {
          component: {
            Optional: {
              exclude: [
                "ProfileForm.general.about",
                "ProfileForm.general.address",
                "ProfileForm.general.social",
                "UserMenu.support",
                "UserMenu.grades",
                "UserMenu.files",
                "UserMenu.library",
                "UserMenu.payments",
                "UserMenu.policiesAndProcedures"
              ],
              include: [
                "ProfileForm.general.footnote",
                "ProfileForm.general.address.readOnly",
                "ProfileForm.general.phone.readOnly",
                "ProfileForm.general.preferredName.readOnly",
                "ProfileForm.general.name.readOnly",
                "ProfileForm.general.email.readOnly"
              ]
            }
          },
          links: {
            lmsCourse: "https://online{envSuffix}.yoobee.ac.nz",
            studentSupport: "mailto:onlinesupport@up.education"
          },
          groups: ["aad"],
          timezone: "Pacific/Auckland", // Server timezone (be nice if we could ask)
          favicon: "/providers/yoobee/favicon32.png",
          logo: "/providers/yoobee/Yoobee_Master_Lockup_01_BLACK_03_22.png",
          logoBg: "#FFF",
          welcomeBg: "/providers/yoobee/sign_background_16.png"
        }
      },
      nzst: {
        global: {
          component: {
            Optional: {
              exclude: [
                "ProfileForm.general.about",
                "ProfileForm.general.address",
                "ProfileForm.general.social",
                "UserMenu.support",
                "UserMenu.grades",
                "UserMenu.files",
                "UserMenu.library",
                "UserMenu.payments",
                "UserMenu.policiesAndProcedures"
              ],
              include: [
                "ProfileForm.general.footnote",
                "ProfileForm.general.address.readOnly",
                "ProfileForm.general.phone.readOnly",
                "ProfileForm.general.preferredName.readOnly",
                "ProfileForm.general.name.readOnly",
                "ProfileForm.general.email.readOnly"
              ]
            }
          },
          links: {
            lmsCourse: "https://online{envSuffix}.nzschooloftourism.co.nz",
            studentSupport: "mailto:onlinesupport@up.education"
          },
          groups: ["aad"],
          timezone: "Pacific/Auckland", // Server timezone (be nice if we could ask)
          favicon: "/providers/nzst/favicon-32x32.png",
          logo: "/providers/nzst/nzst-logo.svg",
          logoBg: "#FFF",
          welcomeBg: "/providers/nzst/welcomeBg-NZST.webp"
        }
      },
      colab: {
        global: {
          component: {
            Optional: {
              exclude: [
                "UserMenu.files",
                "UserMenu.payments",
                "ProfileForm.general.social",
                "GradesPage.pleaseNote"
              ]
            }
          },
          timezone: "Australia/Melbourne", // Server timezone (be nice if we could ask)
          groups: ["avetmiss", "cognito", "au"],
          links: {
            lmsCourse: "https://online{envSuffix}.colab.edu.au",
            home: "https://www.colab.edu.au/",
            studentSupportPolicyLink: "https://www.colab.edu.au/terms-and-conditions",
            complaintsPolicyLink: "https://www.colab.edu.au/terms-and-conditions",
            complaintFormLink: "https://www.colab.edu.au/terms-and-conditions",
            completionPolicyLink: "https://www.colab.edu.au/terms-and-conditions",
            assessmentPolicyLink: "https://www.colab.edu.au/terms-and-conditions"
          },
          favicon: "/providers/colab/ColabFavicon.png",
          logo: "/providers/colab/ColabLogo.svg",
          welcomeBg: "/providers/colab/ColabBg.png",
          logoBg: "#FFF"
        }
      }
    }
  },

  // Use these values if stage is not specified
  unknown: {},

  // Stage specific
  local: {
    global: {
      env: "local",
      google: {
        apiKey: "AIzaSyCe2jT8Fs6xhGy2WDPb5Cke7cLYix11w1w"
      },
      options: {
        persist: false,
        mockUser: true
      }
    },
    upApi: {
      baseUri: upApiOverride || "http://localhost:3030/"
    },
    msal: {
      config: {
        auth: {
          //clientId: "c8da148e-4fdc-4a33-8f5b-8d0a426df13f" // uponline -DEV
          clientId: "8859b570-2cef-4e04-9e95-06ea50df33c7" // uponline -TEST
        }
      },
      loginRequest: {
        //            scopes: ["api://81daff24-4edd-4ec2-a65a-c154bd8f4a32/User.Read", "openid", "email", "profile"]
        scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read", "openid", "email", "profile"]
      },
      tokenRequest: {
        scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read"]
      }
    },
    providers: {
      "up-online": {
        amplify: {
          Auth: {
            region: "us-west-2",
            userPoolId: "us-west-2_k4tZGJ4uY",
            userPoolWebClientId: "6kg0hiiv8gatqo0jt1nt6nhhu2"
          }
        }
      },
      ichm: {
        msal: {
          config: {
            auth: {
              //clientId: "c8da148e-4fdc-4a33-8f5b-8d0a426df13f" // uponline -DEV
              clientId: "8859b570-2cef-4e04-9e95-06ea50df33c7" // uponline -TEST
            }
          },
          loginRequest: {
            //            scopes: ["api://81daff24-4edd-4ec2-a65a-c154bd8f4a32/User.Read", "openid", "email", "profile"]
            scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read", "openid", "email", "profile"]
          },
          tokenRequest: {
            scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read"]
          }
        }
      },
      soe: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_IVMNT9Nym",
            userPoolWebClientId: "4nkjou2u269mcsgcdc69mfrbpg",
            mandatorySignIn: false,
            oauth: {
              domain: "sut-test.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      },
      iahealth: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_7wyHPpMxI",
            userPoolWebClientId: "7ud61a7rp2luaghe3cva82u12k",
            mandatorySignIn: false,
            oauth: {
              domain: "https://iahealth-dev.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      colab: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_Ft53boj5o",
            userPoolWebClientId: "5456uumg11ub9farl9br7q7mvc",
            mandatorySignIn: false,
            oauth: {
              domain: "colab-dev.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      }
    }
  },
  development: {
    global: {
      env: "dev"
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/"
    },
    msal: {
      config: {
        auth: {
          clientId: "c8da148e-4fdc-4a33-8f5b-8d0a426df13f" // uponline -DEV
        }
      },
      loginRequest: {
        scopes: ["api://81daff24-4edd-4ec2-a65a-c154bd8f4a32/User.Read", "openid", "email", "profile"]
      },
      tokenRequest: {
        scopes: ["api://81daff24-4edd-4ec2-a65a-c154bd8f4a32/User.Read"]
      }
    },
    providers: {
      "up-online": {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_XQTMRBOTX",
            userPoolWebClientId: "3r40lk01ekalp5fom58mj34h48",
            oauth: {
              domain: "login-dev.uponline.education"
            }
          }
        }
      },
      soe: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_6IfeKzjOm",
            userPoolWebClientId: "249sd9e9nm3brm27h0vevuqssr",
            mandatorySignIn: false,
            oauth: {
              //domain: "soe-dev.edu.au"
              domain: "sut-dev.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      iahealth: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_7wyHPpMxI",
            userPoolWebClientId: "7ud61a7rp2luaghe3cva82u12k",
            mandatorySignIn: false,
            oauth: {
              domain: "iahealth-dev.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      colab: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_Ft53boj5o",
            userPoolWebClientId: "5456uumg11ub9farl9br7q7mvc",
            mandatorySignIn: false,
            oauth: {
              domain: "colab-dev.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      }
    }
  },
  test: {
    global: {
      env: "test"
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
    },
    msal: {
      config: {
        auth: {
          clientId: "8859b570-2cef-4e04-9e95-06ea50df33c7" // uponline -TEST
        }
      },
      loginRequest: {
        scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read", "openid", "email", "profile"]
      },
      tokenRequest: {
        scopes: ["api://ee58037a-ba2b-4dcf-8bb3-423a20bf6a6c/User.Read"]
      }
    },
    providers: {
      "up-online": {
        amplify: {
          Auth: {
            domain: "login-test.uponline.education",
            userPoolId: "ap-southeast-2_XQTMRBOTX",
            userPoolWebClientId: "3r40lk01ekalp5fom58mj34h48",
            mandatorySignIn: false,
            oauth: {
              domain: "login-test.uponline.education"
            }
          }
        }
      },
      soe: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_IVMNT9Nym",
            userPoolWebClientId: "4nkjou2u269mcsgcdc69mfrbpg",
            mandatorySignIn: false,
            oauth: {
              domain: "sut-test.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      },
      iahealth: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_fo6izIAUE",
            userPoolWebClientId: "3mmgmk34n7a6muashncd6elmc4",
            mandatorySignIn: false,
            oauth: {
              domain: "iahealth-test.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      colab: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_UgMM08EKY",
            userPoolWebClientId: "11061ael5t3n6omscolhkt75sa",
            mandatorySignIn: false,
            oauth: {
              domain: "colab-test.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      }
    }
  },
  uat: {
    global: {
      env: "uat"
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
    },
    msal: {
      config: {
        auth: {
          clientId: "e49a3669-5771-4537-971e-25a2198676a8" // uponline -UAT
        }
      },
      loginRequest: {
        scopes: ["api://bd406f94-df7c-4354-af76-f6e45e1e2ef6/User.Read", "openid", "email", "profile"]
      },
      tokenRequest: {
        scopes: ["api://bd406f94-df7c-4354-af76-f6e45e1e2ef6/User.Read"]
      }
    },
    providers: {
      "up-online": {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_2w3UGtUS4",
            userPoolWebClientId: "5lu99lgj2ei8ktdohk4j4ejqpj",
            oauth: {
              domain: "login-uat.uponline.education"
            }
          }
        }
      },

      soe: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_lPwroF3NF",
            userPoolWebClientId: "4fecbdca1k68091u4r6nr4u1b2",
            mandatorySignIn: false,
            oauth: {
              domain: "sut-uat.auth.ap-southeast-2.amazoncognito.com" //"soe-uat.edu.au"
            }
          }
        }
      },
      iahealth: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_1cxdg3By0",
            userPoolWebClientId: "4qc709tim1f4m9g5ufgvt36rs4",
            mandatorySignIn: false,
            oauth: {
              domain: "iahealth-uat.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      colab: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_PYsUaxPgA",
            userPoolWebClientId: "1sccmoequ6lkbhtucuitkc1mj9",
            mandatorySignIn: false,
            oauth: {
              domain: "colab-uat.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      }
    }
  },
  production: {
    global: {
      env: "prod"
    },
    upApi: {
      baseUri: "https://up-int-apim-prod.azure-api.net/"
    },
    msal: {
      config: {
        auth: {
          clientId: "82cf7bf1-10e7-4584-b600-6759cbffa94c" // uponline -prod
        }
      },
      loginRequest: {
        scopes: ["api://a1bfcbed-6f50-498d-92ee-7adbca6d391c/User.Read", "openid", "email", "profile"]
      },
      tokenRequest: {
        scopes: ["api://a1bfcbed-6f50-498d-92ee-7adbca6d391c/User.Read"]
      }
    },
    providers: {
      iahealth: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_E9BSqwC5z",
            userPoolWebClientId: "37i11esb89ia3d5p0ajv0cea4l",
            mandatorySignIn: false,
            oauth: {
              domain: "iahealth.auth.ap-southeast-2.amazoncognito.com"
            }
          }
        }
      },
      soe: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_BXgJfnPj6",
            userPoolWebClientId: "7uoe25h24q95087k704rb3ridp",
            mandatorySignIn: false,
            oauth: {
              domain: "login.soe.edu.au"
            }
          }
        }
      },
      "up-online": {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_6qRpsvHza",
            userPoolWebClientId: "5j93ftone888i3t2n3vhc5riu9"
          },
          oauth: {
            domain: "login.uponline.education"
          }
        }
      },
      colab: {
        amplify: {
          Auth: {
            userPoolId: "ap-southeast-2_oIEknAhha",
            userPoolWebClientId: "1oq758l8jefnvj8ka6h48egksi",
            mandatorySignIn: false,
            oauth: {
              domain: "colab.auth.ap-southeast-2.amazoncognito.com" //"soe-test.edu.au"
            }
          }
        }
      }
    }
  }
};

// Create a per-stage config
const stageConfig = merge({}, data["default"], data[stage]);

// Select provider based on matching host (overridable with REACT_APP_PROVIDER)
const provider =
  process.env.REACT_APP_PROVIDER ||
  (Object.entries(stageConfig.global.hostMap).find(([provider, re]) => window.location.host.match(new RegExp(re))) || [
    "up-online"
  ])[0];
console.debug(`Provider: ${provider}`);

const providerConfig = stageConfig.providers[provider];
console.debug("Provider config:", providerConfig);

// When we merge configs, arrays are alwayd unioned, objects override ifs keys match
function customizer(obj, src) {
  if (isArray(obj)) return union(obj, src); //merge arrays (e.g. exclude,include) as sets
}

//  provider config inherits config from each of its groups too
const {global: {groups = []} = {}} = providerConfig || {};
const providerWithGroups = mergeWith(
  ...["default", ...(groups || [])].map((group) => stageConfig.providerGroups[group] || {}),
  providerConfig,
  customizer
);

// Calculate base config
export const baseConfig = mergeWith(
  {
    global: {
      slug: provider
    }
  },
  stageConfig,
  providerWithGroups,
  customizer
);

// Transform URLs in links with UP environment suffixes where needed
const {
  global: {env, links}
} = baseConfig;
const envSuffix = env === "prod" ? "" : `-${env}`;
const envLinks = {
  global: {
    links: Object.fromEntries(
      Object.entries(links)
        .filter(([k, v]) => isString(v))
        .map(([k, v]) => [k, v.replaceAll(/\{envSuffix\}/g, envSuffix)])
    )
  }
};

// Merge in development persisted settings
const config = merge({}, baseConfig, envLinks, isDev && loadClientConfig(clientConfigKey));
console.debug(config);
export default config;