import promise from "redux-promise-middleware";

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { upApiReducers } from "./upApiReducer";
import { reducer as form } from "redux-form";
import { getState } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import { mergeWith } from "lodash";
import errorMiddleware from "./errorMiddleware";
/**
 * Initialize a global redux store
 */
let theStore = null;

export function createStore(extraPersistConfig = {}, extraReduxConfig = {}, extraReducers = {}) {
	const persistConfig = {
		storage
	};
	theStore = configureStore(
		mergeWith(
			{
				reducer: persistReducer(
					{...persistConfig, ...extraPersistConfig},
					combineReducers({
						form,
						...upApiReducers,
						...extraReducers
					})
				),
				middleware: [/*errorMiddleware,*/ promise]
			},
			extraReduxConfig,
			(a, b) => {
				if (Array.isArray(a)) {
					return a.concat(b).filter((v) => !!v);
				}
			}
		)
	);
	const persistor = persistStore(theStore);
	return {store: theStore, persistor};
}

/**
 * Given check current action payload in store and if not yet being requested send request
 * For static API data this saves renderloop re-requests if the API is failing
 * @param {*} store
 * @param {*} selector
 * @param {*} actionCreator
 */
export function dispatchOnce(selector, actionCreator, ...args) {
  const payload = selector(theStore.getState());
  if (!payload || (!payload.error && !payload.pending && !payload.data))
    return theStore.dispatch(actionCreator(...args));
  else return Promise.resolve(payload);
}
