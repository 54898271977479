import {Grid, MenuItem} from "@material-ui/core";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Select, TextField, useGlobal} from "up-form";
import {createEmailCase, useEnrolments} from "up-state";
import {selectUser, useCurrentProfile} from "../../../state/appReducers";
import BaseCaseForm from "../BaseCaseForm";
import SupportContent from "./SupportContent";
export default function SupportPage() {
  const {t} = useTranslation();
  const {data: profile} = useCurrentProfile();
  const {
    slug: provider,
    component: {
      SupportPage: {caseDescriptionMax}
    }
  } = useGlobal();
  const {studentId} = useSelector(selectUser) || {};
  const {data: enrolments, data: [{enrolmentCourseId: defaultCourseId} = {}] = []} = useEnrolments(studentId);

  // Move this into sub component for rerender speed when typing
  function CaseDescription() {
    const [descriptionLength, setDescriptionLength] = useState(0);
    return (
      <TextField
        name="description"
        required
        fullWidth
        multiline
        rows={5}
        label={t("SupportForm.enquiry.description.label")}
        inputProps={{maxLength: caseDescriptionMax}}
        onChange={({target: {value}}) => {
          if (value.length !== descriptionLength) setDescriptionLength(value.length);
        }}
        helperText={
          descriptionLength
            ? `Remaining characters ${caseDescriptionMax - descriptionLength}`
            : `Maximum ${caseDescriptionMax} characters`
        }
      />
    );
  }

  return (
    <SupportContent
      renderForm={() => (
        <BaseCaseForm
          createAction={({subType, description, enrolmentCourseId}) => {
            // Most translations are just strings, and assume type=Support otherwise type/subType may be specified in object
            return createEmailCase({
              caseTitle: t("SupportForm.enquiry.caseTitle", {
                profile,
                date: new Date().toLocaleDateString()
              }),
              provider,
              caseType: "Support", // always ignored now
              caseSubType: subType,
              courseEnrolmentId: enrolmentCourseId || defaultCourseId, // sic
              description,
              studentUpId: profile.studentUPId
            });
          }}
          form="support"
        >
          {Array.isArray(enrolments) && (
            <Grid container spacing={3} direction="column">
              <Grid item xs={12} sm={6}>
                <Select
                  defaultValue={enrolments.length === 1 && defaultCourseId}
                  disabled={enrolments.length === 1}
                  required={enrolments.length > 1}
                  fullWidth
                  name="enrolmentCourseId"
                  label={t("SupportForm.enquiry.enrolmentCourseId.label")}
                >
                  {enrolments.map(({enrolmentCourseId, courseName}, i) => (
                    <MenuItem key={i} value={enrolmentCourseId}>
                      {courseName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select required fullWidth name="subType" label={t("SupportForm.enquiry.subType.label")}>
                  {t("SupportForm.enquiry.subTypes", {returnObjects: true}).map((label, i) => (
                    <MenuItem key={i} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <CaseDescription />
              </Grid>
            </Grid>
          )}
        </BaseCaseForm>
      )}
    />
  );
}
