import React from "react";
import {acceptance} from "redux-form-validators";
import BaseCaseForm from "./BaseCaseForm";
import {Typography, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {ChooseOne, Checkbox, TextField, useGlobal} from "up-form";
import {useCurrentProfile} from "../../state/appReducers";
import {createCase} from "up-state";

const WithdrawalForm = () => {
  const {t} = useTranslation(),
    {data: profile} = useCurrentProfile();
  const {slug} = useGlobal();

  return (
    <BaseCaseForm
      createAction={({caseSubtype, description}) =>
        createCase({
          caseTitle: t("WithdrawalForm.caseTitle", {
            profile,
            date: new Date().toLocaleDateString()
          }),
          caseType: "Support",
          caseSubtype,
          description,
          studentUpId: profile.studentUPId,
          provider: slug
        })
      }
      form="withdrawal"
      title={t("WithdrawalForm.title")}
    >
      <Typography variant="body1" gutterBottom>
        Before proceeding with your withdrawal request please read the T&amp;C’s below carefully, that you signed upon enrolling
        into this course. The Refund Period is defined as <b>fourteen (14) calendar days</b> from the day that the student’s
        enrolment conditions have been met. The enrolment conditions have been met when all of the following have occurred:
        <ol>
          <li>A student accepts the terms and conditions of their Student Agreement</li>
          <li>UP Learning has received a student’s deposit or full payment</li>
          <li>If paying in full, a student has been granted access to their learning materials</li>
        </ol>
        The Refund Period commences from the last date that all 3 things have occurred (generally when the login is provided). A
        Cancellation Request received within the Refund Period will always be issued less the applicable administrative fee. By
        submitting the withdrawal request, you are reconfirming that you understand and accept these T&amp;C’s
      </Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChooseOne
            name="caseSubtype"
            label={t("WithdrawalForm.subtype.label")}
            options={[
              {
                label: t("WithdrawalForm.subtype.withinCOP"),
                value: "Cancellation In-COP"
              },
              {
                label: t("WithdrawalForm.subtype.outsideCOP"),
                value: "Cancellation Out-COP"
              },
              {
                label: t("WithdrawalForm.subtype.blank"),
                value: undefined
              }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField name="description" fullWidth multiline rows={5} label={t("WithdrawalForm.description.label")} />
        </Grid>
        <Grid item>
          <Checkbox name="agree" validate={[acceptance()]} label={t("WithdrawalForm.agree.label")} />
        </Grid>
      </Grid>
    </BaseCaseForm>
  );
};

export default WithdrawalForm;
