import {createReducer, createAction} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {useProfile} from "up-state";
export const changeUser = createAction("app/user/change");

const appReducers = createReducer(
  {
    user: undefined
  },
  (builder) => {
    builder.addCase(changeUser, (state, action) => {
      state.user = action.payload;
    });
  }
);

export const selectUser = ({app: {user}}) => user;

export function useCurrentProfile() {
  const {studentId} = useSelector(selectUser) || {};
  const profile = useProfile(studentId);
  return profile;
}

export default appReducers;
