import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import ExternalLink from "../../content/ExternalLink";

export default function PolicyContent() {
  const {
    links: {handbook}
  } = useGlobal();
  const {t} = useTranslation();

  return (
    <>
      <h1>Policies</h1>
      <p>
        At NZMA we are committed to offering quality learning. We are ranked as a Category 1 provider on NZQA's EER rating which is
        the best rating possible for providers.
      </p>
      <p>
        This means that we are on the highest tier of private tertiary educators and operate under a four-year NZQA External
        Evaluation and Review (EER) cycle.
      </p>
      <h2>Student Handbook</h2>
      <p>
        All the {t("Provider.shortName")} regulations, policies, and further information about your learning, including complaints
        procedures and academic appeals, can be found in your&nbsp;
        <ExternalLink href={handbook}>Student Handbook</ExternalLink>
      </p>
    </>
  );
}
