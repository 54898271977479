import {Box, Button, CircularProgress, Grid, InputAdornment, makeStyles, Snackbar, Typography, Link} from "@material-ui/core";
import Facebook from "@material-ui/icons/Facebook";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Twitter from "@material-ui/icons/Twitter";
import {Alert} from "@material-ui/lab";
import React, {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {reduxForm} from "redux-form";
import {Country, SectionHeading, State, TextField, useGlobal} from "up-form";
import {updateProfile, useMetadata} from "up-state";
import {Optional, useExcludedSet} from "up-form";
import SkeletonGrid from "../SkeletonGrid";
import {toForm, toStudent} from "./mapProfileForm";

const allTextProps = {fullWidth: true, notRequiredText: ""};
const useStyles = makeStyles((theme) => ({
  socialPrefix: {
    color: theme.palette.text.secondary
  }
}));

export const GeneralForm = reduxForm({
  form: "profile",
  destroyOnUnmount: false,
  enableReinitialize: false
})(({profile, dirty, valid, handleSubmit, submitting, initialize}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {data: metadata} = useMetadata();
  const [initialized, setInitialized] = useState(false);
  const [saveStatus, setSaveStatus] = useState();
  const {succeeded} = saveStatus || {};
  const resetForm = useCallback(() => {
    profile && metadata && initialize(toForm(profile, metadata));
  }, [initialize, profile, metadata]);
  const {
    preview,
    links: {studentSupport}
  } = useGlobal();
  const options = useExcludedSet();
  const readOnly = ["about", "email", "name", "preferredName", "address", "phone", "social"].reduce((acc, opt) => ({
    [opt]: !options.has(`ProfileForm.general.${opt}.readOnly`),
    ...acc
  }));
  const social = [
    {
      name: "twitter",
      url: "twitter.com/",
      icon: <Twitter style={{color: "#1da1f2"}} />
    },
    {
      name: "facebook",
      url: "facebook.com/",
      icon: <Facebook style={{color: "#1877f2"}} />
    },
    {
      name: "linkedin",
      url: "linkedin.com/in/",
      icon: <LinkedIn style={{color: "#0077B5"}} />
    }
  ];
  const either = (value, {homePhone, mobilePhone}) =>
    !(homePhone || mobilePhone) && t("ProfileForm.general.error.asLeastOnePhoneRequired");

  useEffect(() => {
    if (profile && metadata && !initialized) {
      resetForm();
      setInitialized(true);
    }
  }, [initialize, profile, metadata, initialized, resetForm]);
  return (
    <form
      onSubmit={handleSubmit((f) => {
        const studentId = profile.studentUPId;
        return dispatch(updateProfile(toStudent({studentId}, f)))
          .then((p) => {
            initialize(f); // removes dirty without refreshing form from API
            setSaveStatus({succeeded: true});
            return p;
          })
          .catch((error) => {
            setSaveStatus({succeeded: false});
            console.error(error);
          });
      })}
    >
      <SectionHeading title={t("ProfileForm.general.title")} dirty={dirty} />
      <Box m={2} mb={3}>
        {preview ? (
          <SkeletonGrid rows={12} columns={2} />
        ) : (
          <Grid container spacing={2} margin={2}>
            <Grid item xs={12}>
              <Optional name="ProfileForm.general.about">
                <TextField
                  name="personalSummary"
                  label={t("ProfileForm.general.about.label")}
                  multiline
                  InputProps={{readOnly: readOnly.about}}
                  {...allTextProps}
                />
              </Optional>
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("ProfileForm.general.firstName.label")}
                required
                name="firstName"
                InputProps={{readOnly: readOnly.name}}
                {...allTextProps}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("ProfileForm.general.lastName.label")}
                required
                name="lastName"
                InputProps={{readOnly: readOnly.name}}
                {...allTextProps}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label={t("ProfileForm.general.preferredName.label")}
                name="preferredName"
                InputProps={{readOnly: readOnly.name}}
                {...allTextProps}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("ProfileForm.general.email.label")}
                type="email"
                name="emailAddress"
                InputProps={{readOnly: readOnly.email}}
                {...allTextProps}
              />
            </Grid>
            <Optional name="ProfileForm.general.phone">
              <Grid item xs={12} sm={6}>
                <TextField
                  type="tel"
                  autoComplete="mobile"
                  label={t("ProfileForm.general.mobilePhone.label")}
                  validate={either}
                  name="mobilePhone"
                  InputProps={{readOnly: readOnly.phone}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="tel"
                  autoComplete="home"
                  label={t("ProfileForm.general.homePhone.label")}
                  validate={either}
                  name="homePhone"
                  InputProps={{readOnly: readOnly.phone}}
                  {...allTextProps}
                />
              </Grid>
            </Optional>
            <Optional name="ProfileForm.general.address">
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.buildingPropertyName.label")}
                  name="buildingPropertyName"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.flatUnitDetails.label")}
                  name="flatUnitDetails"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.streetOrLotNumber.label")}
                  name="streetOrLotNumber"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.streetName.label")}
                  name="streetName"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.suburb.label")}
                  name="suburb"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.city.label")}
                  name="city"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Optional name="ProfileForm.general.state.dropdown">
                <Grid item xs={12} sm={6}>
                  <State label={t("ProfileForm.general.state.label")} disabled={readOnly.address} name="state" />
                </Grid>
              </Optional>
              <Optional name="ProfileForm.general.state.text">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("ProfileForm.general.state.label")}
                    name="stateName"
                    InputProps={{readOnly: readOnly.address}}
                    {...allTextProps}
                  />
                </Grid>
              </Optional>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t("ProfileForm.general.postcode.label")}
                  name="postcode"
                  InputProps={{readOnly: readOnly.address}}
                  {...allTextProps}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Country label={t("ProfileForm.general.country.label")} name="country" fullWidth disabled={readOnly.address} />
              </Grid>
            </Optional>
            <Optional name="ProfileForm.general.social">
              {social.map(({icon, url, name}, i) => (
                <Grid key={i} item xs={12}>
                  <TextField
                    InputProps={{
                      readOnly: readOnly.social,
                      startAdornment: (
                        <InputAdornment className={classes.socialPrefix} position="start">
                          {icon}
                          {url}
                        </InputAdornment>
                      )
                    }}
                    label={t(`ProfileForm.general.${name}.label`)}
                    name={name}
                    {...allTextProps}
                  />
                </Grid>
              ))}
            </Optional>
          </Grid>
        )}
        <Optional name="ProfileForm.general.footnote">
          <Typography variant="h5">{t("ProfileForm.general.footnote.title")}</Typography>
          <Typography>
            <Trans i18nKey="ProfileForm.general.footnote.text" components={{supportLink: <Link href={studentSupport} />}} />
          </Typography>
        </Optional>
      </Box>
      {dirty && (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button disabled={!dirty} variant="contained" onClick={resetForm}>
              {t("ProfileForm.reset.label")}
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" disabled={submitting || !dirty || !valid} variant="contained" type="submit">
              {submitting && <CircularProgress size="1em" style={{marginRight: "1em"}} />} {t("ProfileForm.save.label")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Snackbar autoHideDuration={6000} open={!!saveStatus} onClose={() => setSaveStatus(null)}>
        <Alert severity={succeeded ? "success" : "error"}>
          {t(`ProfileForm.${succeeded ? "profileSaveSucceeded" : "profileSaveFailed"}.text`, {profile})}
        </Alert>
      </Snackbar>
    </form>
  );
});
