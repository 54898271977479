/**
 * Content component for support form
 */
import PropTypes from "prop-types";
import { useGlobal } from "up-form";
import ExternalLink from "../../content/ExternalLink";
import UpPicture from "../../UpPicture";

export const SupportContentIntro = () => {
  return (
    <>
      <p>
        At Colab, we want to support you throughout your study and ensure that you succeed at achieving your
        goals. We know that you lead busy lives with lots of different priorities and that your study makes up one part of a much
        bigger picture. Our ultimate goal is to keep you motivated and engaged in your studies.
      </p>
      <p>
        We have the tools and people to best help you succeed, whether you are looking to answer your own question through our FAQs
        and community forums or you would like to speak to one of our dedicated support team members, our passionate
        staff are here to help answer questions, motivate you, and celebrate when you succeed.
      </p>
      <h2>Ask for Support</h2>
      <p>
        We are here to help you, so if you couldn’t find the answer to your question above, feel free to reach out to us below. In
        order for us to resolve your questions in a timely manner please provide us with as much detail as possible. We will get
        back to you as soon as possible, so please look out for a response in your email inbox.
      </p>
    </>
  )
}

export default function SupportContent({renderFaqs: FAQSet, ...other}) {
  const {
    slug,
    links: {
      studentSupportPolicyLink,
      complaintsPolicyLink,
      complaintFormLink,
      completionPolicyLink,
      assessmentPolicyLink
    }
  } = useGlobal();
  function Image({name, uri = `/providers/${slug}/image/support/${name}-assets/${name}`, ...other}) {
    return <UpPicture class="image" uri={uri} {...other} />;
  }
  return (
    <>
      <h2>Getting Started</h2>
      <p>
        Following your enrolment, you will receive all the information you need to get started, including login details to
        your learning platform. Here you will have access to everything you need to complete your study, including your interactive
        course and learning materials, access to support services, personalised communications and access to peers completing the
        same qualification.
      </p>
      <FAQSet
        faqs={[
          {
            q: "Top tips for getting started",
            a: (
              <ul>
                <li>Take the tour of our platform to see all the features we have created to help you succeed</li>
                <li>Attend one of our dedicated online Orientation tutorial sessions</li>
                <li>Get started with your first module.</li>
                <ol className="alpha">
                  <li>Read through your learning materials.</li>
                  <li>Complete the interactive learning activities.</li>
                </ol>
                <li>When you feel ready, submit your first assessment.</li>
                <ol className="alpha">
                  <li>You will receive a grade of competent or not yet competent and detailed feedback from your trainer.</li>
                </ol>
                <li>Reach out if you need support, we are here to help you succeed.</li>
              </ul>
            )
          }
        ]}
      />
      <Image name="support-image-1" alt="A student seated at their desk, embarking on their studies" />
      <h2>Your support</h2>
      <p>
        Everyone needs a little help sometimes, that is why we offer lots of different types of support to fit your needs. This is
        your team to lean on when you need some extra help or motivation during your studies.  We want you to succeed.
      </p>
       <h3>Student Support</h3>
      <p>
        Our friendly Student Support team can help you with all your non-academic specific questions. These may range from updating
        your student details to helping you resolve a technical issue.
      </p>
      <h3>Academic Support</h3>
      <p>
        Your Academic team are here to help you understand the course materials, have questions about your assessment and ensure
        that you are learning.
      </p>
      <p>
        When you submit assessments, you will receive detailed feedback that will help to deepen your understanding of the material.
      </p>
      <p>
      Attend live Q&A sessions, designed so that everyone can be involved even if they cannot attend the support session ‘live’. 
      Students can do this by taking their queries directly to their assessor prior to the relevant live Q&A via a message in the 
      learning Platform. These questions will be addressed during the live Q&A and will also be recorded so you will be able to view 
      the session in your own time. All recordings are stored in the 'Additional Resources' thread, which is ‘pinned’ within the Unit 
      1 and 2 forums.
      </p>
      <FAQSet
        faqs={[
          {
            q: "What can Student Support help me with?",
            a: (
              <>
                 <p>Here are just some of the ways that Student Support can help whilst you study:</p>
                <ul>
                  <li>Enrolment questions</li>
                  <li>Update your personal details</li>
                  <li>Help logging in to your Learning Platform </li>
                  <li>Contact your trainer</li>
                  <li>Guidance on submitting your assessment</li>
                  <li>Course extension requests</li>
                  <li>Handle any cancellation requests</li>
                </ul>
                <p>
                  The best way to contact Student Support is right here in your Learning Platform.  The ‘Ask for Support’
                  section below allows you to select which type of support you need and provide us with all the relevant details to
                  best support you.
                </p>
                <ExternalLink href={studentSupportPolicyLink}>Student Support Policy and Procedure</ExternalLink>
              </>
            )
          },
          {
            q: "When do I contact my trainer?",
            a: (
              <>
                <p>
                  It is natural when you are learning something new that you will need support or have questions. Your academic team
                  are here to help you if you have questions about course materials,  your assessments or need help
                  staying motivated.
                </p>
                <p>
                  You can message your trainer at any time through your Learning Platform by clicking on the Message Centre icon on
                  the home page. You can also engage in their forum posts within your course.
                </p>
                <ExternalLink href={studentSupportPolicyLink}>Learning Policy</ExternalLink>
              </>
            )
          },
          {
            q: "How do I stay motivated while I study?",
            a: "Online study provides greater flexibility to reach your goals around your busy lifestyle. Setting your own study schedule and having a clear and achievable goals is important to staying on track, similar to going to the gym. There will be times where you may lose momentum or motivation but this is normal and happens to all of us, so don’t give up. Most importantly reach out when you need support and we will help you."
          },
          {
            q: "I need technical support with the Learning Platform",
            a: (
              <>
                From time to time you may experience some technical issues with your Learning Platform that you need support with.
                When this occurs, reach out to Student Support and they will help troubleshoot the issue with you or request for the
                issue to be resolved with our technical team.
              </>
            )
          },
          {
            q: "What if I need extra time to complete my study?",
            a: (
              <>
                <p>
                  We understand that life can get in the way sometimes and you may need some additional support during this time.
                  There are different options available to you based on your own personal circumstances.
                </p>

                <p>
                  You can find further information about these options in the Student Support Policy and Procedure, or by discussing
                  your situation in more detail with Student Support.
                </p>

                <ExternalLink href={studentSupportPolicyLink}>Student Support Policy and Procedure</ExternalLink>
              </>
            )
          },
          {
            q: "Who should I contact if I am unhappy with the service?",
            a: (
              <>
                <p>
                  We will always aim to resolve all your questions or concerns through discussions with Student Support. The Student
                  Support team are passionate about you achieving your study goals and will do everything in their power to resolve
                  your issue in a timely manner. For further information please refer to the Complaints Policy.
                </p>
                <p>
                  <ExternalLink href={complaintsPolicyLink}>Complaints Policy</ExternalLink>
                </p>

                <p>
                  Alternatively, you may decide to engage with the formal complaint process by submitting a formal complaint. For
                  further information, including processing times please refer to the Complaints Policy. In order to investigate
                  your concerns and provide a fair and reasonable outcome, we suggest that you provide evidence to support your
                  claim. Supporting evidence may include copies of emails, phone records, supporting letters from institutions or
                  service providers, medical certificates, police reports, insurance claims, court of legal documentation, financial
                  documents, screenshot of issues, web links, references to materials, statutory declarations or any other documents
                  you feel will be relevant to your case.
                </p>
                <p>
                  <ExternalLink href={complaintFormLink}>Complaint Form</ExternalLink>
                </p>
              </>
            )
          }
        ]}
      />
      <Image name="support-image-3" alt="A student seated at their desk, looking at their phone" />
      <h2>My Assessments</h2>
      <p>
        Your course will be assessed online through your learning platform. You will be assigned a specialist Trainer for each
        module who will provide support while you complete your assessment. At the end of each module and prior to the next module,
        skill and knowledge are evaluated through assessments. Assessment consists of online quizzes, assignments and supervised
        tests.
      </p>
      <FAQSet
        faqs={[
          {
            q: "How long does it take to mark my assessment?",
            a: "Once you submit your assessment it will be over to your trainer to review and grade. You will receive a grade within 10 business days and will be notified through the notification tab within the Learning Platform and via email. It can take up to 10 business days as your trainer will be providing you with detailed feedback to ensure that you are supported and have a deep understanding of the content."
          },
          {
            q: "How are my assessments graded?",
            a: (
              <>
                <p>
                  All assessments are based on the concept of competence. As a result, your assessments will be graded as either
                  competent or not yet competent.
                </p>

                <p>
                  Competent means that you have successfully demonstrated the required skills and knowledge to pass this assessment.
                </p>

                <p>Not yet competent means additional work is required to demonstrate the required skills and knowledge.   </p>
                <p>
                  <ExternalLink href={assessmentPolicyLink}>Assessment Policy and Procedure</ExternalLink>
                </p>
              </>
            )
          },
          {
            q: "What happens if I fail an assessment?",
            a: (
              <>
                <p>
                  Firstly, don’t worry, you cannot fail an assessment. You may receive a grade of not yet competent which means
                  there is additional work required to demonstrate the required skills and knowledge, and we’re here to help.  Your
                  Trainer will provide you will detailed feedback on your assessment which will guide you to make the changes
                  required to resubmit. This may include revisiting some of the learning content to better prepare you for
                  resubmission.  Spending time going through your feedback is a great way to guarantee success next time.
                </p>

                <p>You can also reach out to your trainer for further support at any time.</p>
              </>
            )
          },
          {
            q: "What if I need help with an assessment?",
            a: (
              <p>
                The best person to support you with an assessment is your Trainer, they are just a message away. You can contact
                them through the message centre within the Learning Platform.
              </p>
            )
          }
        ]}
      />
      <Image name="support-image-4" alt="A student on their laptop" />
          <h3>Support after Graduating</h3>
      <p>Once you have completed your course, you will receive the following:</p>
      <ul>
        <li>
          Testamur – once you have successfully completed all the course requirement, you will be issued with a certificate of
          completion.
        </li>

        <li>
          Academic Transcript (for Nationally Recognised qualifications only) – this record is a list of the competencies you have
          achieved.
        </li>
      </ul>
      <FAQSet
        faqs={[
          {
            q: "How long will it take to receive my certificate?",
            a: (
              <>
                <p>
                  Firstly, congratulations on completing your study. Now that you have finished all the hard work, we want you to
                  get your certificate as soon as possible.
                </p>
                 <p>There are a few things you can do to speed up the process:</p>
                <ul>
                  <li>Check that your address is up to date and correct on your profile page </li>

                  <li>Ensure that the spelling of your name is correct </li>

                  <li>Ensure that we have your USI  </li>

                  <li>Make sure you have paid any outstanding fees </li>
                </ul>
                <p>If all of the above are satisfied and completed, you should receive your certificate within 20 working days. </p>
                <p>
                  <ExternalLink href={completionPolicyLink}>Completion Policy and Procedure</ExternalLink>
                </p>
              </>
            )
          }
        ]}
      />
      <Image name="support-image-5" alt="A student reaching out to their trainer, asking for support" />
    </>
  );
}

SupportContent.propTypes = {
  renderFaqs: PropTypes.func
};