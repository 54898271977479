import React from "react";
import Place from "@material-ui/icons/Place";
export const Location = ({ profile }) => {
  const location = [profile.city, profile.country].filter(v => !!v).join(",");
  return (<>
    {location && (<span>
      <Place style={{ verticalAlign: "middle" }} />
      {location}
    </span>)}
  </>);
};
