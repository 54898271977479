import PropTypes from "prop-types";
import React from "react";

import {Chip, withStyles} from "@material-ui/core";
import {green, grey, red} from "@material-ui/core/colors";

function ResultChip({externalGrade, resultText, classes}) {
  const level = /(High )?Distinction|Credit|Pass Level (1|2)|(Conceded|Non Graded) Pass/i.test(externalGrade)
    ? classes.success
    : /Fail Level (1|2)|Withdrawn failed/i.test(externalGrade)
    ? classes.fail
    : /Result Incomplete/i.test(externalGrade)
    ? classes.incomplete
    : classes.unknown;
  return <Chip className={level} variant="outlined" label={resultText}></Chip>;
}

ResultChip.propTypes = {
  classes: PropTypes.shape({
    fail: PropTypes.any,
    incomplete: PropTypes.any,
    success: PropTypes.any,
    unknown: PropTypes.any
  }),
  externalGrade: PropTypes.string,
  resultText: PropTypes.string.isRequired
};

export default withStyles({
  success: {
    color: green[500],
    borderColor: green[500]
  },
  fail: {
    color: red[500],
    borderColor: red[500]
  },
  incomplete: {
    color: grey[500],
    borderColor: grey[500]
  },

  unknown: {
    color: grey[500],
    borderColor: grey[500]
  }
})(ResultChip);
