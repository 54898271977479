import PropTypes from "prop-types";
import {Skeleton} from "@material-ui/lab";

/**
 * Simple grid of skeleton areas for showing previews
 */
export default function SkeletonGrid({
  columns = 1,
  rows = 1,
  gap = "1em",
  height = "5em",
  ...other
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns},1fr)`,
        columnGap: gap,
        gridAutoRows: height
      }}
    >
      {[...new Array(rows * columns)].map((_, i) => (
        <Skeleton key={i} {...other} />
      ))}
    </div>
  );
}

SkeletonGrid.propTypes = {
  columns: PropTypes.number,
  gap: PropTypes.string,
  height: PropTypes.string,
  rows: PropTypes.number
};
