import {Avatar, Card, CardHeader, makeStyles} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useStudentImage} from "up-state";
import {selectUser} from "../../state/appReducers";
import AvatarEditor from "./AvatarEditor";
import {Location} from "./Location";
import {useGlobal} from "up-form";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  header: ({reverse}) => ({
    flexDirection: reverse ? "row-reverse" : "row"
  }),
  avatar: ({reverse}) => ({
    [reverse ? "marginLeft" : "marginRight"]: theme.spacing(1),
    border: "solid 2px rgba(0,0,0,0)",
    "&:hover": {
      borderColor: theme.palette.action.hover
    }
  }),
  avatarEditIcon: {
    fontSize: "small",
    position: "relative",
    top: "-16px",
    left: "38px"
  }
}));

export const IdentityCard = ({
  profile,
  variant = "normal",
  noName: noNameProp,
  noLocation: noLocationProp,
  reverse,
  editAvatar,
  ...props
}) => {
  const classes = useStyles({reverse});
  const {studentId} = useSelector(selectUser) || {};
  const {data: studentImage} = useStudentImage(studentId);
  const name = ["firstName", "lastName"]
    .map((k) => profile && profile[k])
    .filter((v) => !!v)
    .map((v) => v.slice(0, 1).toUpperCase() + v.slice(1));
  const fullName = name.join(" ");
  const initials = name.map((v) => v.slice(0, 1)).join("");
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const compact = variant === "compact",
    noName = noNameProp || compact,
    noLocation = noLocationProp || compact;
  const {preview} = useGlobal();
  return (
    <Card {...props}>
      {preview ? (
        <CardHeader
          avatar={<Skeleton variant="circle" style={{width: 48, height: 48}} />}
          title={<Skeleton style={{width: "20em", height: "2em"}} />}
          subheader={<Skeleton style={{width: "10em", height: "2em"}} />}
        />
      ) : (
        <>
          <CardHeader
            className={classes.header}
            avatar={
              <label>
                {editAvatar && (
                  <input
                    style={{display: "none"}}
                    capture="user"
                    accept="image/*"
                    type="file"
                    value=""
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                )}
                <Avatar className={classes.avatar} alt={fullName} src={imageUrl || studentImage}>
                  {initials}
                </Avatar>
                {editAvatar && <Edit className={classes.avatarEditIcon} />}
              </label>
            }
            title={!noName && fullName}
            subheader={profile && !noLocation && <Location profile={profile} />}
          />
          {editAvatar && file && (
            <AvatarEditor
              profile={profile}
              file={file}
              onChange={setImageUrl}
              onClose={() => {
                setFile(null);
                setImageUrl(null);
              }}
            />
          )}
        </>
      )}
    </Card>
  );
};

IdentityCard.propTypes = {
  editAvatar: PropTypes.any,
  noLocationProp: PropTypes.any,
  noNameProp: PropTypes.any,
  profile: PropTypes.shape({
    firstName: PropTypes.any,
    lastName: PropTypes.any
  }),
  reverse: PropTypes.any,
  variant: PropTypes.oneOf(["compact", "normal"])
};
