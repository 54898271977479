/**
 * Content component for support form
 */
import {Collapse, IconButton, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {useState} from "react";
import {FiChevronDown} from "react-icons/fi";
import {useGlobal} from "up-form";
import ExternalLink from "../../content/ExternalLink";

const SupportContent = ({classes, renderForm: SupportForm, ...other}) => {
  const {
    links: {placementPolicy, policiesAndProcedures}
  } = useGlobal();
  function Section({title, children}) {
    const [open, setOpen] = useState(false);
    return (
      <section>
        <Typography class="sectionHeading" variant="h2" onClick={() => setOpen(!open)}>
          {title}
          &nbsp;
          <IconButton>
            <FiChevronDown class="collapse" style={open && {transform: "rotate(-180deg)"}} />
          </IconButton>
        </Typography>
        <Collapse timeout={1000} in={open}>
          {children}
        </Collapse>
      </section>
    );
  }
  return (
    <>
      <img class="image" src="/providers/ichm/welcomeBg.svg" height={"10%"} alt="" />
      <h1>Support</h1>
      <p>
        ICHM is committed to developing graduates with a level of transferable skills making them experts in their chosen focus
        areas such as marketing, hospitality or entrepreneurship but professional generalists in the overarching discipline of
        business.
      </p>
      <p>
        We pride ourselves in providing an ICHM community which is inclusive, engaging and committed to each student being able to
        share their individual strengths and attributes.
      </p>
      <p>
        The Bachelor of Business, Bachelor of Business (Marketing) and Bachelor of Business (Entrepreneurship) are fully online and
        do not require on-campus attendance, students will access all student, academic and support services via the 'Ask for
        Support' form.
      </p>
      <h1>Ask for Support</h1>
      <p>
        On this page you will find several resources designed to assist you with your study, but we are also here to help, feel free
        to reach out using the contact form. For us to resolve your questions in a timely manner please provide us with as much
        detail as possible. We will get back to you within 48 business hours, o please look out for a response in your email inbox.
      </p>
      <SupportForm />
      <Section title="Getting Started">
        Following your enrolment, you will receive all the information you need to get started, including login details to your
        learning platform. Upon first login you will receive an automated overview of the platform and will have access to an
        Orientation unit, designed to assist you in becoming familiar with the platform and how to study at ICHM. You will have
        access to everything you need to complete your study, including your interactive course and learning materials, access to
        support services, personalised communications and access to peers completing the same qualification.
      </Section>
      <Section title="Study Support and Engagement">
        <h3>Academic Support &amp; Assistance</h3>
        <p>
          ICHM provides all students with the academic support needed to fully appreciate and respond to the demands of study and
          online campus life. It is an environment second to none when it comes to enhancing students' development and applying that
          knowledge in their professional life.
        </p>
        <p>
          We offer a comprehensive range of academic support services enabling students to access the assistance that best suits
          their learning needs, from one-on-one support to small or large group workshops. Additionally, you can access academic
          support by engaging with your lecturing staff via the message centre or within each subject forum.
        </p>
        <p>ICHM also has specialist literacy and learning support staff to guide students.</p>
        <p>
          <b>Academic Learning Support</b> - available 9am to 5pm on weekdays (ACST)
        </p>
        <h3>Student Counselling &amp; Welfare</h3>
        <p>
          Our Student Welfare Officers ensure all students have the support and guidance they need to address any issue that might
          adversely affect their studies and/ or general well-being while at ICHM.
        </p>
        <p>
          As the student's first point of contact, our Welfare Officer works closely with students guiding, managing and resolving
          any situations that may arise from personal, cultural or study-related challenges.
        </p>
        <p>
          ICHM's Welfare Officer is also involved with student induction and plays a key role in ensuring ICHM students are well
          integrated into the unique ICHM 'family' culture.
        </p>
        <h3>Student services &amp; ICHM administration</h3>
        <p>
          All students at ICHM have access to a range of services to assist them during their study. These services include a
          comprehensive student induction program as well as ongoing guidance.
        </p>
        <p>
          A Student Engagement Group formed by students enhances social activities and helps with any academic issues. From uniforms
          and timetables to organising graduation ceremonies, ICHMs administration staff are on hand to assist with all general
          student enquiries.
        </p>
        <p>
          <b>Administration and Admissions</b> - available 8.30am to 4.30pm on weekdays
        </p>
        <p>
          <b>(ACST) Critical incident support</b> - available 24/7, contact details provided to students when they commence
        </p>
        <h3>Library services</h3>
        <p>
          Library services can be found via the IHEA Library and provides access to many resources which you will need to complete
          your studies whilst a student at ICHM. The professional library staff are very helpful and skilled in showing you how to
          find resources online. You can access our library via the I-Campus.
        </p>
        <h3>Industry and Career Development (ICD)</h3>
        <p>
          ICHM is focused on producing career-ready grads and has dedicated industry focused members of Academic staff to ensure you
          get the development you need to enter industry as a change-maker for the future. The development of your employability
          skills is paramount to your industry success.
        </p>
        <p>
          The ICD teamwork provides you the opportunity to work in a professional environment, using and enhancing the skills and
          knowledge you have developed during your studies at ICHM.
        </p>
        <p>
          Each member of the ICD team has significant industry experience enabling them to assist students with their placements;
          but also provide an insight into the career paths available to them.
        </p>
        <p>
          ICHM's Student Employability Development Model showcases the range of development opportunities available to you as you
          progress through the embedded Work Integrated Learning (WIL) program.
        </p>
      </Section>
      <Section title="Work Integrated Learning (WIL)">
        <p>
          ICHM prides itself on the quality and number of Work Integrated Learning (WIL) placements available to its students. These
          placements form an integral and extremely valuable part of all the ICHM degree programs.
        </p>
        <p>
          ICHM works with a number of valued industry partners across hospitality and broader business industries, both within
          Australia and around the world, to secure placements which will enhance the career prospects of each individual student.
        </p>
        <h3>About Work Integrated Learning (WIL)</h3>
        <p>
          Work Integrated Learning (WIL) placements provide an opportunity which enables you to consolidate the skills and knowledge
          you have acquired in practical, professional settings to achieve the subject learning outcomes and complete assessments
          which integrate theory and practice and facilitate self-reflection and critical analysis.
        </p>
        <p>
          In addition to participating in the workforce with a host company, as part of a structured internship or creating their
          own entrepreneurship venture, the students will also complete a placement project on an issue of strategic value to their
          host company or own venture, as described in the next section.
        </p>
        <p>
          Students will critically reflect upon their own development plan and progress through the preparation of a reflective
          assessment that considers the impact of identified workplace experiences on their personal development and future career
          progression opportunities. This subject also allows students the opportunity to explore their professional interests; test
          their industry expectations or possible start-up creations and ideas; apply, practice and develop real world employability
          skills; and form professional networks for future career success.
        </p>
        <h3>What to Expect</h3>
        <p>
          ICHM’s WIL process has been designed to ensure you leave your degree industry ready and are able to hit the ground
          running. You should expect the following from your curated WIL experience:
        </p>
        <ul>
          <li>The ability to put into practice the skills and knowledge learnt during the on-campus semesters</li>
          <li>In-depth on-the-job experience</li>
          <li>Insight into the world of work and the responsibilities it involves</li>
          <li>Realistic experiences of the hotel and other business environments</li>
          <li>The opportunity to experience different company cultures, systems &amp; work practices</li>
          <li>Rigorous and relevant assessment</li>
          <li>An insight into a range of career paths</li>
          <li>Personal growth and independence</li>
          <li>Networking opportunities for future employment.</li>
        </ul>
        <h3>Process</h3>
        <p>
          The WIL process begins early in the study period prior to your placement. Students are asked to list their preferred
          destination on their WIL placement request form, prepare their resume and meet with the Industry Career &amp; Development
          (ICD) Managers. ICHM's ICD Managers research the available positions at suitable host organisations. If a student meets an
          organisation's requirements, the ICD Managers formally apply on their behalf. From here the selection requirements will
          vary across organisations; some placements require face to- face, telephone or online interviews with the host
          organisation making the final decision on the student's eligibility for placement.
        </p>
        <h3>Position</h3>
        <p>
          ICHM will always endeavour to locate a position within a host organisation in a preference area for the student.
          Organisations available along with their location may also impact on the position available.
        </p>
        <p>
          The search for the right position for each student is undertaken by both the student and their assigned Industry Career &
          Development (ICD) Manager working together to ensure the right role is sourced. ICHM aims to find WIL placements for
          students in positions deemed suitable to fulfill the requirements of the course, and the student's demonstrated
          capabilities at that time.
        </p>
        <p>
          Students may source their own WIL placement as long as it meets the ICHM host organisation and role requirements. If a
          student currently holds a role within an industry environment meeting the ICHM WIL placement requirements they are welcome
          and encouraged to maintain that role as part of the WIL program.
        </p>
        <h3>Location</h3>
        <p>ICHM encourages all students to keep their career goals in mind when nominating placement.</p>
        <p>
          Where a WIL placement is a student's first role within an industry environment, it is strongly recommended they stay in
          Australia or their home country.
        </p>
        <p>International placements may be possible subject to a number of factors:</p>
        <ul>
          <li>The student's ability to obtain a work visa for that country (as this is their own responsibility)</li>
          <li>They must be able to financially support themselves during the period</li>
          <li>Their ability to communicate in that country's language.</li>
        </ul>
        <p>
          There are limits to the number of students ICHM can place in properties and locations, so sometimes the student's first
          choice cannot be accommodated.
        </p>
        <h3>Earnings &amp; Expenses</h3>
        <p>
          ICHM delivers a mixture of both paid and unpaid Work Integrated Learning (WIL) placements. If the student is able to
          secure a paid placement they will be remunerated in accordance with Australian National Employment Standards and any
          corresponding industrial award.
        </p>
        <p>
          Unpaid WIL placements allow for a student to experience an industry environment which may more closely align with their
          future career goals and enable them to view a variety of roles within the business disciplines.
        </p>
        <p>
          Students considering specific WIL placement offers will have ample opportunity to assess the conditions and expected
          outcomes before beginning the application process.
        </p>
        <h3>Monitoring</h3>
        <p>
          The Industry Career &amp; Development (ICD) team makes contact with all students whilst they complete their WIL placement.
          This contact comes in a variety of forms including, online meetings, phone calls, and industry onsite visits.
        </p>
        <p>
          An ICHM WIL lecturer is assigned to ensure the WIL journey is structured and assessment assistance can be provided
          throughout the trimester.
        </p>
        <p>
          The ICD team along with the ICHM Student Welfare team are available for the student's entire WIL journey to ensure
          success.
        </p>
        <h3>Assessment</h3>
        <p>
          A number of assessment approaches are utilised to ensure that each student is able to extract the most from their assigned
          Work Integrated Learning (WIL) placement.
        </p>
        <p>
          Host organisation employers are required to assess students across a variety of performance measures while on WIL
          placement to provide feedback. This feedback is then reviewed to allow students to continue to reflect on and enhance the
          development of their employability skills to ensure they are career-ready upon completion of the Bachelor degree.
        </p>
        <p>
          Students must also complete a set of authentic subject assessment criteria while on placement. Refer to the&nbsp;
          <ExternalLink href={placementPolicy}>WIL Placement Policy</ExternalLink> and the&nbsp;
          <ExternalLink href={policiesAndProcedures}>Student Policies &amp; Procedures</ExternalLink>&nbsp;for additional details
          regarding WIL placements.
        </p>
      </Section>
      <Section title="Reasonable Adjustment">
        <p>
          Reasonable adjustments may be made to accommodate a student with a learning difficulty or physical difference and reduce
          the impact of these challenges on a student's academic success.
        </p>
        <p>
          Adjustments may include modifications to the learning environment, teaching method, or assessment conditions to increase
          the participation of a student without compromising the academic standard or the inherent course requirements.
        </p>
        <p>
          ICHM does not restrict enrolment on the basis of physical or learning difference or discriminate against students with a
          disability. However, ICHM may deny entry based upon reasonable belief of a student's inability to successfully complete
          the course, based upon potential limitations applied by the disability, and restricted participation within course
          requirements.
        </p>
        <p>Please refer to the Reasonable Adjustment policy for further information.</p>
        <p>
          If your special circumstance requires the provision of a reasonable adjustment for this subject, please seek advice at the
          commencement of your subject from the Program Director Academic or Student Support.
        </p>
      </Section>
      <Section title="Student Conduct">
        <p>
          ICHM seeks to prepare students to meet or exceed the demands and expectations of industry. The highest standards of
          courtesy and professionalism by staff and students in all aspects of study at ICHM are expected. ICHM students are
          expected to conduct themselves in a manner which does not impair the functioning of the College and the reasonable freedom
          of other persons to pursue their studies, research, duties or lawful activities of the College or to participate in the
          life of the College and to observe the ICHM Student Code of Behaviour. An ICHM student who does not conduct themselves in
          such a manner may be reported for misconduct under this Personal Conduct Policy.
        </p>
        <p>Definitions of misconduct can be found in the Personal Conduct policy.</p>
        <p>
          In addition, ICHM is committed to ensuring all students are communicated within a reliable, timely, effective, and
          efficient manner.
        </p>
        <p>
          Students should consider their etiquette, written communication, and verbal communication in accordance with the ICHM
          personal conduct policy and ICHM communications policy
        </p>
      </Section>
      <Section title="Assessment">
        <h3>Submission dates and extensions</h3>
        <p>
          Failure to submit work by the nominated “due time and date” will result in a penalty of 5% of the total available mark for
          each and every day beyond the due time and date. An extension on the due time and date for an assignment or report may be
          granted by the subject lecturer for a period of up to two weeks. Written application on the appropriate&nbsp;
          <ExternalLink href="https://myacg.sharepoint.com/sites/ICHMStudentInformationHub2/Shared%20Documents/Forms%20and%20Templates/Assessment%20Extension%20Request%20Form.docx">
            Extension Request
          </ExternalLink>
          &nbsp;form must be made a minimum of 5 days prior to the due date of the assessment, at which point a draft copy of work
          commenced must be presented to the subject lecturer.
        </p>
        <p>
          All ICHM students are provided with access to their own dedicated 'OneDrive' account. It is the student's responsibility
          to use this account to save and backup all written assessment work. Requests for extensions due to the loss of work based
          upon failing to use OneDrive will not be accepted.
        </p>
        <p>
          If an extension greater than two weeks is required, an email request must be received by the Chair of the Academic
          Committee, Kellie Lumsden - Program Director Academic 5 days prior to the due date of the assessment, at which point a
          draft copy of work commenced must be presented.
        </p>
        <p>
          If an extension is being sought based on medical grounds, the approved ICHM Medical Certificate form must be used to
          support this application.
        </p>
        <p>
          If an extension is granted and the work is not submitted by the new, approved due time and date, a 0% will be recorded for
          the assessment point.
        </p>
        <h3>Supplementary Assessment</h3>
        <p>
          A subject lecturer may award a supplementary assessment where a student attains 45% to 49% in an assessment point. The
          supplementary assessment may be a resubmission of the original assessment piece. This supplementary assessment must be
          completed within 10 weekdays of the student being notified by the subject lecturer. Where a supplementary assessment has
          been awarded, the maximum mark achievable for the assessment point is 50%.
        </p>
        <p>
          The Academic Committee may award a supplementary assessment when considering final results for subjects. Such
          supplementary assessments could be awarded on medical, compassionate, academic or other special considerations. Where a
          supplementary assessment has been awarded for the subject, the maximum mark achievable for the subject is 50%. A
          supplementary assessment must be submitted completed within 10 weekdays of the student being notified by the Academic
          Committee unless additional provisions have been made by the committee. Failure to submit by the revised due date will
          result in a Fail grade of the supplementary assessment. Failure of the supplementary assessment will result in a Fail
          grade for the subject.
        </p>
        <p>
          In the granting of a supplementary assessment the full range of grades may be available for situations involving medical,
          compassionate or special considerations. This must be approved by the Academic Committee.
        </p>
        <h3>Submitting assessments</h3>
        <p>
          It is the responsibility of all students to safeguard against all potential breaches of Academic and Research Integrity. -
          Refer to I-Campus (LMS) for information on Assessment submissions. Your assessment will be submitted to Turnitin and in
          doing so, students are deemed to have declared that their assessment is entirely their own work upon submission.
          Furthermore, students are deemed to have declared that the work has not been previously submitted for a subject of the
          College, or any other educational institution.
        </p>
        <p>
          Further information on submitting an assignment and checking for academic integrity using Turnitin can be found here&nbsp;
          <ExternalLink href="https://help.turnitin.com/feedback-studio/turnitin-website/student/student-category.htm">
            https://help.turnitin.com/feedback-studio/turnitin-website/student/student-category.htm
          </ExternalLink>
        </p>
      </Section>
    </>
  );
};

export default SupportContent;
SupportContent.propTypes = {
  renderForm: PropTypes.func
};
