import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {useGlobal} from "up-form";
import SupportFormNzma from "./nzma/SupportPage";
import SupportFormIchm from "./ichm/SupportPage";
import SupportFormSoe from "./soe/SupportPage";
import SupportFormColab from "./colab/SupportPage"
import WithdrawalForm from "./WithdrawalForm";
import ContentPage from "../content/ContentPage";

const Support = () => {
  const {path} = useRouteMatch();
  const {slug} = useGlobal();
  const SupportForm = {
    nzma: SupportFormNzma,
    "nz-institute-of-sport": SupportFormNzma,
    ichm: SupportFormIchm,
    soe: SupportFormSoe,
    colab: SupportFormColab
  }[slug];

  return (
    <Switch>
      <Route path={`${path}/withdraw`}>
        <WithdrawalForm />
      </Route>
      <Route exact path={`${path}/general`}>
        <ContentPage>{SupportForm && <SupportForm />}</ContentPage>
      </Route>
    </Switch>
  );
};

const Cases = () => {
  const {path} = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/support`}>
          <Support />
        </Route>
      </Switch>
    </>
  );
};

export default Cases;
