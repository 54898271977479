import {Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Typography, withStyles, Box} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Select, TextField, useGlobal} from "up-form";
import {selectUser, useCurrentProfile} from "../../../state/appReducers";
import {useEnrolments} from "up-state";
import SupportContent, {SupportContentIntro} from "./SupportContent";
import BaseCaseForm from "../BaseCaseForm";
import {useSelector} from "react-redux";
import {createCase} from "up-state";

const SupportPage = withStyles(
  ({
    palette: {
      secondary,
      action: {hover}
    }
  }) => {
    return {
      faqRoot: {
        border: "none",
        "&:before": {
          backgroundColor: "transparent"
        },
        "&.Mui-expanded": {
          backgroundColor: "#EAEAEF"
        }
      },
      question: {
        "& p": {
          fontWeight: "bold"
        },
        "&:hover": {
          backgroundColor: hover,
          "& .MuiIconButton-label": {
            color: secondary.main
          }
        }
      },
      answer: {
        display: "block"
      }
    };
  }
)(({classes}) => {
  const {t} = useTranslation();
  const {data: profile} = useCurrentProfile();
  const {
    slug,
    component: {
      SupportPage: {caseDescriptionMax}
    }
  } = useGlobal();
  const {studentId} = useSelector(selectUser) || {};
  const {data: enrolments, data: [{enrolmentCourseId: defaultCourseId} = {}] = []} = useEnrolments(studentId);

  // Move this into sub component for rerender speed when typing
  function CaseDescription() {
    const [descriptionLength, setDescriptionLength] = useState(0);
    return (
      <TextField
        name="description"
        required
        fullWidth
        multiline
        rows={5}
        label={t("SupportForm.enquiry.description.label")}
        inputProps={{maxLength: caseDescriptionMax}}
        onChange={({target: {value}}) => {
          if (value.length !== descriptionLength) setDescriptionLength(value.length);
        }}
        helperText={
          descriptionLength
            ? `Remaining characters ${caseDescriptionMax - descriptionLength}`
            : `Maximum ${caseDescriptionMax} characters`
        }
      />
    );
  }

  return (
    <>
      <BaseCaseForm
        createAction={({subType, description, enrolmentCourseId}) =>
          createCase({
            caseTitle: t("SupportForm.enquiry.caseTitle", {
              profile,
              date: new Date().toLocaleDateString()
            }),
            caseType: "Support",
            caseSubType: subType,
            courseEnrolmentId: enrolmentCourseId || defaultCourseId, // sic
            description,
            studentUpId: profile.studentUPId,
            provider: slug
          })
        }
        form="support"
        title={t("SupportForm.title")}
      >
        {Array.isArray(enrolments) && (
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={12}>
              <SupportContentIntro />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue={enrolments.length === 1 && defaultCourseId}
                disabled={enrolments.length === 1}
                required={enrolments.length > 1}
                fullWidth
                name="enrolmentCourseId"
                label={t("SupportForm.enquiry.enrolmentCourseId.label")}
              >
                {enrolments.map(({enrolmentCourseId, courseName}, i) => (
                  <MenuItem key={i} value={enrolmentCourseId}>
                    {courseName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select required fullWidth name="subType" label={t("SupportForm.enquiry.subType.label")}>
                {Object.entries(t("SupportForm.enquiry.subTypes", {returnObjects: true})).map(([label, subType], i) => (
                  <MenuItem key={i} value={subType}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <CaseDescription />
            </Grid>
          </Grid>
        )}
      </BaseCaseForm>
      <Box padding={4}>
        <SupportContent
          renderFaqs={({faqs}) => {
            const [expanded, setExpanded] = React.useState(false);

            const handleChange = (panel) => (event, isExpanded) => {
              setExpanded(isExpanded ? panel : false);
            };

            return (
              <>
                {faqs &&
                  faqs.map(({q, a}, i) => {
                    const panel = `panel${i}`;
                    const isExpanded = expanded === panel;
                    return (
                      <Accordion
                        key={i}
                        elevation={isExpanded ? 2 : undefined}
                        variant={isExpanded ? "elevation" : "outlined"}
                        expanded={isExpanded}
                        onChange={handleChange(`panel${i}`)}
                        className={classes.faqRoot}
                      >
                        <AccordionSummary
                          className={classes.question}
                          key={i}
                          expandIcon={isExpanded ? <RemoveCircle /> : <AddCircle />}
                        >
                          <Typography variant="body1">{q}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.answer}>
                          <Typography component="span" variant="body1">
                            {a}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </>
            );
          }}
        />
      </Box>
    </>
  );
});

export default SupportPage;
