import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./components/app/App";
import "./index.css";

import { isDev, stage, buildId, commit, branch } from "./config";

console.log(
  `Stage: ${stage}\nBuild: ${buildId} (${
    isDev ? "development" : "production"
  })\nBranch: ${branch}\nCommit: ${commit}`
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
