import React from "react";
import {IconButton, Drawer, Grid, makeStyles, Box} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {IdentityCard} from "../profile/IdentityCard";
import ListMenu from "../navigation/ListMenu";
import {burgerMenu} from "../navigation/menus";
const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%"
    },
    backgroundColor: theme.palette.background.default
  }
}));

export const BurgerMenu = ({onClose, profile, open}) => {
  const classes = useStyles();
  return (
    <Drawer classes={classes} anchor="left" variant="temporary" open={open} onClose={onClose}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
        <Grid item>
          <IdentityCard profile={profile} noBadge noLocation elevation={0} reverse />
        </Grid>
      </Grid>
      <Box p={2}>
        <ListMenu menu={burgerMenu} onClick={onClose} />
      </Box>
    </Drawer>
  );
};
