import PropTypes from "prop-types";
import {Box, Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {reduxForm} from "redux-form";
import {Message} from "up-form";
import {createCase} from "up-state";

const BaseCaseForm = reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true
})(
  ({
    title,
    busy,
    createAction = createCase,
    children,
    dirty,
    handleSubmit,
    valid,
    submitting,
    submitSucceeded,
    submitFailed,
    reset,
    dispatch
  }) => {
    const {t} = useTranslation();
    const submitAction = (form) => dispatch(createAction(form)).then(reset);
    return (
      <Box padding={2}>
        {title && <Typography variant="h1">{title}</Typography>}
        <form onSubmit={handleSubmit(submitAction)}>
          <Box padding={3}>{children}</Box>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={() => window.history.back()}>
                {t("CaseForm.cancel.label")}
              </Button>
            </Grid>
            <Grid item>
              <Button disabled={!dirty} variant="contained" onClick={reset}>
                {t("CaseForm.reset.label")}
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" disabled={submitting || !dirty || !valid || busy} variant="contained" type="submit">
                {busy && <CircularProgress size="1em" />}
                {t("CaseForm.send.label")}
              </Button>
            </Grid>
          </Grid>
        </form>
        {<Message open={submitting} variant="busy" message={t("CaseForm.sending")} />}
        {<Message open={submitFailed} variant="error" message={t("CaseForm.failed")} onClose={reset} />}
        {
          <Message
            open={submitSucceeded}
            variant="success"
            message={t("CaseForm.succeeded")}
            autoHideDuration={5000}
            onClose={reset}
          />
        }
      </Box>
    );
  }
);

BaseCaseForm.propTypes = {
  busy: PropTypes.bool,
  children: PropTypes.any,
  createAction: PropTypes.func,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  reset: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  title: PropTypes.any,
  valid: PropTypes.bool
};

export default BaseCaseForm;
