import {Box, Button, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {useDispatch, useSelector} from "react-redux";
import {studentImage, updateStudentImage} from "up-state";
import {selectUser} from "../../state/appReducers";

const AvatarEditor = ({file, onChange, onClose}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [crop, setCrop] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [blob, setBlob] = useState(null);
  const {studentId} = useSelector(selectUser) || {};
  async function getCroppedImg(image, crop, file) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = file;
          resolve(blob);
        },
        "image/jpeg",
        100
      );
    });
  }

  function handleClose() {
    setImageRef(null);
    setCroppedImageUrl(null);
    setImagePreviewUrl(null);
    setCrop(null);
    onChange && onChange(null);
    onClose && onClose();
  }
  const save = (blob) => dispatch(updateStudentImage(studentId, blob)).then(() => dispatch(studentImage({studentId})));

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    function cleanup() {
      if (croppedImageUrl) window.URL.revokeObjectURL(croppedImageUrl);
    }
    if (!dragging && imageRef && crop.width && crop.height) {
      getCroppedImg(imageRef, crop, file).then(async (blob) => {
        cleanup();
        const newUrl = window.URL.createObjectURL(blob);
        setCroppedImageUrl(newUrl);
        onChange && onChange(newUrl);
        setBlob(blob);
      });
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef, crop, dragging]);
  return (
    <Box padding={3}>
      {imagePreviewUrl && (
        <Box marginBottom={2}>
          <ReactCrop
            src={imagePreviewUrl}
            crop={crop}
            circularCrop
            ruleOfThirds
            onImageLoaded={(image) => {
              setImageRef(image);
              const size = Math.min(image.width, image.height);
              setCrop({
                unit: "px",
                width: size,
                x: (image.width - size) / 2,
                y: (image.height - size) / 2,
                aspect: 1 / 1
              });
              return false;
            }}
            onComplete={setCrop}
            onChange={setCrop}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
            imageStyle={{maxHeight: "30vh"}}
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="contained" onClick={handleClose}>
            {t("ProfileForm.cancel.label")}
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={!blob || !studentId} variant="contained" color="primary" onClick={() => save(blob).then(handleClose)}>
            {t("ProfileForm.save.label")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AvatarEditor;
