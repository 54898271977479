import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Paper, Link } from "@material-ui/core";
import { Trans } from "react-i18next";
const ErrorPage = () => {
  const { status } = useParams();

  return (
    <Paper>
      <Box padding="10em" margin="auto">
        <Typography variant="subtitle2">
          <Trans
            i18nKey={`ErrorPage.${status}.text`}
            components={{
              homeLink: <Link href="/" />
            }}
          />
        </Typography>
      </Box>
    </Paper>
  );
};

export default ErrorPage;
