import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import filesize from "filesize";
import moment from "moment";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FiDownload} from "react-icons/fi";
import UpApi from "up-api";
import {Message, useGlobal} from "up-form";
import {useFiles} from "up-state";
import Recent from "../icons/Recent";
import SimpleTable from "../table/SimpleTable";
import {selectUser, useCurrentProfile} from "../../state/appReducers";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  name: {
    textTransform: "none",
    wordBreak: "break-all",
    fontWeight: "500"
  },
  size: {
    fontSize: "x-small",
    paddingLeft: "1em"
  },
  modified: {
    fontSize: "x-small"
  },
  category: {
    marginLeft: "1em"
  }
}));

const FilesPage = () => {
  const {studentId} = useSelector(selectUser) || {};
  const {data: files, error} = useFiles(studentId),
    {
      data: {studentUPId}
    } = useCurrentProfile(),
    {t} = useTranslation(),
    {
      component: {
        FilesPage: {pageSize = 10, recentPeriod = "PT24H"}
      }
    } = useGlobal(),
    now = moment(),
    recent = now.clone().subtract(moment.duration(recentPeriod));
  const FileCard = ({file: {name, size, category, modified}}) => {
    const classes = useStyles(),
      modifiedDate = modified && moment(modified),
      isRecent =
        modifiedDate &&
        modifiedDate.isBefore(now) &&
        modifiedDate.isAfter(recent);
    return (
      <Grid direction="column">
        <Grid item>
          {isRecent && <Recent />}
          <Typography
            display="inline"
            variant="subtitle1"
            className={classes.name}
          >
            {name}
          </Typography>
          {size && (
            <Typography
              display="inline"
              variant="subtitle2"
              className={classes.size}
            >
              {filesize(size)}
            </Typography>
          )}
        </Grid>
        {modifiedDate && (
          <Typography component="span" className={classes.modified}>
            {t("FilesPage.table.size", {
              since: modifiedDate.fromNow()
            })}
          </Typography>
        )}
      </Grid>
    );
  };

  const Download = ({file}) => {
    const [fileError, setFileError] = useState(null),
      [downloadFilename, setDownloadFilename] = useState(null),
      [downloadUrl, setDownloadUrl] = useState(null),
      downloadLink = useRef(null),
      [busy, setBusy] = useState(false);
    const download = async (file) => {
      try {
        setBusy(true);
        setDownloadUrl(
          window.URL.createObjectURL(
            await UpApi.studentFile(studentUPId, file.id)
          )
        );
        setDownloadFilename(file.name);
        downloadLink.current.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        setFileError(err);
      } finally {
        setBusy(false);
      }
    };
    return (
      <>
        {busy ? (
          <CircularProgress variant="indeterminate" />
        ) : (
          <IconButton onClick={() => download(file)}>
            <FiDownload />
          </IconButton>
        )}
        {
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            style={{display: "none"}}
            download={downloadFilename}
            ref={downloadLink}
            href={downloadUrl}
          />
        }
        {fileError && (
          <Message variant="error" message={fileError.message} open />
        )}
      </>
    );
  };

  return (
    <Box pt={1}>
      <Typography variant="h1">{t("FilesPage.title")}</Typography>
      <Typography paragraph variant="body1">
        {t("FilesPage.intro")}
      </Typography>
      {files && studentUPId && (
        <SimpleTable
          itemName="File"
          emptyLabel={t("FilesPage.noFiles")}
          rows={files.files}
          renderRow={(file) => [
            <FileCard file={file} />,
            <Download file={file} />
          ]}
          pageSize={pageSize}
          sort={{
            Date: ({modified: a}, {modified: b}) =>
              moment(a || undefined) - moment(b || undefined),
            Name: ({name: a}, {name: b}) => (a || "").localeCompare(b || ""),
            Size: ({size: a}, {size: b}) => a - b
          }}
        />
      )}
      {error && <Message variant="error" message={error.message} open />}
    </Box>
  );
};

export default FilesPage;
