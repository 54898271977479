import {Container, CssBaseline, Paper} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import {default as i18n} from "i18next";
import PropTypes from "prop-types";
import React, {useRef} from "react";
import {I18nextProvider, useTranslation} from "react-i18next";
import {IconContext} from "react-icons";
import {Redirect, Route, Router, Switch, useLocation} from "react-router-dom";
import {EditConfig, GlobalProvider, useGlobal} from "up-form";
import authenticator from "../../actions/Authenticator";
import config, {clientConfigKey, isDev} from "../../config";
import {createProviderTheme} from "../../themes";
import MyEnrolmentsPage from "../enrolments/MyEnrolmentsPage";
import ErrorPage from "../error/ErrorPage";
import FilesPage from "../files/FilesPage";
import GradesPage from "../grades/GradesPage";
import LibraryPage from "../library/LibraryPage";
import PaymentsPage from "../payments/PaymentsPage";
import PolicyPage from "../policy/PolicyPage";
import ProfileForm from "../profile/ProfileForm";
import Cases from "../support/Cases";
import UpWelcomePanel from "../up/UpWelcomePanel";
import FirstLogin from "../user/FirstLogin";
import Login from "../user/Login";
import {SignInPage} from "../user/SignInPage";
import {SignOutPage} from "../user/SignOutPage";
import Footer from "./Footer";
import {HomePage} from "./HomePage";
import StudentPortalAppBar from "./StudentPortalAppBar";

const PrivateRoute = ({path, exact, component, strict, children, anonymous = () => <UpWelcomePanel busy />}) => {
  const {pathname} = useLocation();
  return (
    <Login
      anonymous={() => (
        <GlobalProvider preview>
          <SignInPage forwardTo={pathname}>{anonymous()}</SignInPage>
        </GlobalProvider>
      )}
    >
      <Route path={path} exact={exact} strict={strict} component={component}>
        {children}
      </Route>
    </Login>
  );
};

const Page = ({headerRef, children, site}) => {
  const {t} = useTranslation(),
    {pathname} = useLocation(),
    pathNorm = pathname.split(/\//).filter((v) => !!v && v.match(/^[A-Za-z]+$/)),
    pathKey = pathNorm.map((v) => v.toLowerCase()).join(".") || "home";
  return (
    <GlobalProvider
      page={{
        name: t(`Page.${pathKey}`),
        path: pathname,
        normalized: pathNorm
      }}
      site={site}
    >
      <StudentPortalAppBar ref={headerRef} />
      <Paper elevation={1}>
        <Container style={{minHeight: "calc(100vh - 2.5em)"}}>{children}</Container>
      </Paper>
    </GlobalProvider>
  );
};
const JsonEditor = React.lazy(() =>
  import(/* webpackChunkName: "jsoneditor" */ "jsoneditor-react").then(({JsonEditor}) => ({default: JsonEditor}))
);
const ProviderPage = ({history}) => {
  const {
    slug,
    groups = [],
    links: {lmsLogout}
  } = useGlobal();
  const header = useRef(null),
    theme = createProviderTheme(slug);

  /**
   * For pages under a specific provider, we add the namesapce of the provider as the default, falling back to
   * the translations for the groups
   *
   */

  const i18forProvider = i18n.cloneInstance({
    ns: ["translation", ...groups, slug],
    defaultNS: slug,
    fallbackNS: [...groups, "translation"]
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IconContext.Provider value={{color: theme.palette.contrastText}}>
        <I18nextProvider i18n={i18forProvider}>
          <Router history={history}>
            <Switch>
              <Route>
                <Page headerRef={header} site={slug}>
                  <Switch>
                    <Route exact path="/" render={() => (authenticator.isRedirecting ? <SignInPage /> : <HomePage />)} />
                    <PrivateRoute exact path="/profile" component={ProfileForm} anonymous={() => <ProfileForm />} />
                    <PrivateRoute exact path="/lms" component={FirstLogin} />
                    <PrivateRoute path="/case" component={Cases} />
                    <Route exact path="/signout">
                      <SignOutPage redirectUrl={lmsLogout} />
                    </Route>
                    <Route exact path="/signoutLms">
                      <SignOutPage initiateLogout />
                    </Route>
                    <PrivateRoute exact path="/files" component={FilesPage} />
                    <PrivateRoute exact path="/grades" component={GradesPage} />
                    <PrivateRoute exact path="/library" component={LibraryPage} />
                    <PrivateRoute exact path="/myEnrolments" component={MyEnrolmentsPage} />
                    <PrivateRoute exact path="/payments" component={PaymentsPage} />
                    <PrivateRoute exact path="/policies" component={PolicyPage} />
                    <Route path="/error/:status(\d{3})" component={ErrorPage} />
                    {isDev && (
                      <Route exact path="/edit/config">
                        <EditConfig config={config} clientConfigKey={clientConfigKey} JsonEditor={JsonEditor} />
                      </Route>
                    )}
                    <Route>
                      <Redirect to="/error/404" />
                    </Route>
                  </Switch>
                  <Footer />
                </Page>
              </Route>
            </Switch>
          </Router>
        </I18nextProvider>
      </IconContext.Provider>
    </ThemeProvider>
  );
};

ProviderPage.propTypes = {
  history: PropTypes.any
};

export default ProviderPage;
