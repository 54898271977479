/**
 * Footer bar
 */
import React from "react";
import {Grid, Typography, Link as ExtLink} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as Logo} from "./up-logo.svg";
import {Link} from "up-form";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: "0 2rem 0 2rem",
    fontSize: "xx-small",
    clear: "both",
    minHeight: "2.5em",
    borderTop: `solid 1px ${theme.palette.divider}`
  },
  logo: {
    marginRight: "0.5em",
    fill: theme.palette.text.secondary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    verticalAlign: "middle"
  },
  links: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
    "& *": {
      whiteSpace: "pre-line"
    },
    "& *:not(:last-child)": {
      borderRight: `solid 1px ${theme.palette.divider}`
    }
  }
}));

export default function Footer(props) {
  const {t, i18n} = useTranslation(),
    classes = useStyles(),
    {
      buildId = "development",
      isDev,
      links,
      formOptions: {footer: {showUpLogo} = {}}
    } = useGlobal();
  return (
    <Grid container direction="column">
      <Typography className={classes.root} variant="body2" component="footer">
        <Grid container className={classes.links} justifyContent="flex-start" alignItems="center" spacing={2}>
          {showUpLogo && (
            <Grid item>
              <ExtLink target="_blank" rel="noopener" href={links.home} color="textSecondary">
                <Logo className={classes.logo} />
                {t("Footer.links.copyright")}
              </ExtLink>
            </Grid>
          )}
          {["home", "privacy", "cookies", "terms", "sitemap"]
            .filter((key) => links[key] && i18n.exists(`Footer.links.${key}`))
            .map((key, i) => (
              <Grid key={i} item xs={i === 0}>
                <ExtLink target="_blank" rel="noopener" href={links[key]} color="textSecondary">
                  <Trans i18nKey={`Footer.links.${key}`} />
                </ExtLink>
              </Grid>
            ))}
          {isDev && (
            <Grid item>
              <Link style={{fontSize: "xx-small"}} to="/edit/config" color="textPrimary">
                Dev Config
              </Link>
            </Grid>
          )}
        </Grid>
      </Typography>
      <Typography variant="caption" style={{fontSize: "xx-small", textAlign: "right"}}>
        {buildId}
      </Typography>
    </Grid>
  );
}
