import PropTypes from "prop-types";
import React from "react";
import {ListItemIcon, ListItemText, ListItem, List} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {SectionHeading} from "up-form";
import {NavLink, useGlobal} from "up-form";
import {Optional} from "up-form";

export default function ListMenu({menu, onClick}) {
  const {t} = useTranslation();
  const {links} = useGlobal();

  return (
    <>
      <SectionHeading title={t(`${menu.name}.title`)} />
      <List>
        {(menu.items || []).map(({href, to, icon, name, secondary}, i) => {
          const externalLink = href && links[href];
          return (
            <Optional name={`${menu.name}.${name}`}>
              <ListItem
                key={i}
                component={NavLink}
                to={(!externalLink && to) || undefined}
                href={externalLink}
                onClick={onClick}
                color="textPrimary"
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText inset={!icon} secondary={secondary && t(`${menu.name}.${name}.secondary`)}>
                  {t(`${menu.name}.${name}.label`)}
                </ListItemText>
              </ListItem>
            </Optional>
          );
        })}
      </List>
    </>
  );
}

ListMenu.propTypes = {
  anchorEl: PropTypes.any,
  menu: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.node,
        href: PropTypes.string, // global links key - if takes precedent over to
        to: PropTypes.string // router path
      })
    )
  }),
  onClose: PropTypes.func,
  onClick: PropTypes.any
};
