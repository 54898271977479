import React from "react";
import {FiAward, FiInfo, FiUser, FiLifeBuoy, FiDollarSign, FiFile, FiBookOpen} from "react-icons/fi";
import {IoIosLogOut} from "react-icons/io";

/**
 * Data for menu contents
 */
export const userMenu = {
  name: "UserMenu",
  items: [
    {icon: <FiUser />, name: "settings", to: "/profile"},
    // { icon: <FiGrid/>, name: "dashboard", to: "/dashboard" },
    {icon: <FiAward />, name: "grades", to: "/grades"},
    {icon: <FiAward />, name: "myEnrolments", to: "/myEnrolments"},
    {
      icon: <FiDollarSign />,
      name: "payments",
      to: "/payments"
    },
    {icon: <FiFile />, name: "files", to: "/files"},
    {
      icon: <FiLifeBuoy />,
      name: "support",
      to: "/case/support/general"
    },
    {icon: <FiBookOpen />, name: "library", to: "/library"},
    {
      icon: <FiInfo />,
      name: "policiesAndProcedures",
      href: "policiesAndProcedures",
      to: "/policies"
    },
    {
      icon: <IoIosLogOut />,
      name: "logout",
      to: "/signoutLms"
    }
  ]
};

export const burgerMenu = {
  name: "BurgerMenu",
  items: [
    {icon: <FiUser />, name: "settings", to: "/profile"},
    // { icon: <FiGrid/>, name: "dashboard", to: "/dashboard" },
    {icon: <FiAward />, name: "grades", to: "/grades"},
    {
      icon: <FiDollarSign />,
      name: "payments",
      to: "/payments"
    },
    {icon: <FiFile />, name: "files", to: "/files"},
    {
      icon: <FiLifeBuoy />,
      name: "support",
      to: "/case/support/general"
    },
    {
      name: "withdraw",
      to: "/case/support/withdraw"
    },
    {
      icon: <IoIosLogOut />,
      name: "logout",
      to: "/signoutLms"
    }
  ]
};
