import React from "react";
import {ButtonBase, useMediaQuery, useTheme} from "@material-ui/core";
import {IdentityCard} from "../profile/IdentityCard";
import { useCurrentProfile } from "../../state/appReducers";
import PopupMenu from "../navigation/PopupMenu";
import {userMenu} from "../navigation/menus";
const AccountButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null),
    {data: profile} = useCurrentProfile();
  const theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <ButtonBase
        variant="text"
        color="secondary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        {...props}
      >
        <IdentityCard
          style={{backgroundColor: "transparent"}}
          profile={profile}
          noLocation
          variant={isMobile ? "compact" : "normal"}
          elevation={0}
          reverse
        />
      </ButtonBase>
      <PopupMenu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} menu={userMenu} />
    </>
  );
};
export default AccountButton;
