import React from "react";
import {IdentityCard} from "./IdentityCard";
import {Courses} from "./Courses";
import {SecurityForm} from "./SecurityForm";
import {GeneralForm} from "./GeneralForm";
import {useCurrentProfile} from "../../state/appReducers";
import {Optional, useGlobal} from "up-form";

const ProfileForm = () => {
  const {data: profile} = useCurrentProfile();
  const {component: {ProfileForm: {showCourseSectionFirst} = {}}} = useGlobal();
  return (
    <>
      <Optional name="ProfileForm.identity">
        <IdentityCard profile={profile} elevation={0} editAvatar />
      </Optional>
      {showCourseSectionFirst && (<Optional name="ProfileForm.courses">
        <Courses profile={profile} />
      </Optional>)}
      <Optional name="ProfileForm.general">
        <GeneralForm profile={profile} />
      </Optional>
      <Optional name="ProfileForm.security">
        <SecurityForm profile={profile} />
      </Optional>
      {!showCourseSectionFirst && (<Optional name="ProfileForm.courses">
        <Courses profile={profile} />
      </Optional>)}
    </>
  );
};

export default ProfileForm;
