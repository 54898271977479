import PropTypes from "prop-types";
import {Link} from "@material-ui/core";

/**
 * A link to an external document. Link content is url host/path if no children given
 * @param {*} param0
 * @returns
 */
export default function ExternalLink({href, children, ...other}) {
  const url = new URL(href, document.location.origin);
  return (
    <Link href={href} target="_blank" rel="noreferrer noopener" underline="always" {...other}>
      {children || `${url.hostname}${url.pathname}`}
    </Link>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.any
};
