import {Box, Grid, Paper, Typography} from "@material-ui/core";
import ExitToApp from "@material-ui/icons/ExitToApp";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router";
import {Message} from "up-form";
import authenticator from "../../actions/Authenticator";

export const SignOutPage = ({redirectUrl, initiateLogout}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [{success, pending, error} = {}, setSignOut] = useState({
    success: !initiateLogout
  });

  useEffect(() => {
    (async () => {
      if (initiateLogout && !(success || pending || error)) {
        setSignOut({pending: true});
        try {
          await authenticator.signOut();
          setSignOut({success: true});
        } catch (error) {
          setSignOut({error});
        }
      } else if (redirectUrl) {
        console.log(`Redirecting to ${redirectUrl}`);
        document.location.href = redirectUrl;
      }
    })();
  }, [dispatch, redirectUrl, success, pending, error, initiateLogout]);
  return (
    <Grid container justifyContent="center" alignItems="center" style={{height: "80vh"}}>
      <Paper variant="outlined">
        <Box padding={5}>
          {
            <Typography variant="h2">
              <ExitToApp color="secondary" fontSize="large" style={{verticalAlign: "middle", marginRight: ".25em"}} />
              {success && t("SignOutPage.success")}
              {pending && t("SignOutPage.pending")}
            </Typography>
          }
          <Message open={pending} variant="busy" message={SignOutPage.busy} />
          {error && <Redirect to={`/error/500?message=${SignOutPage.failed}`} />}
        </Box>
      </Paper>
    </Grid>
  );
};
