import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Message} from "up-form";
import authenticator from "../../actions/Authenticator";
import {selectUser, useCurrentProfile} from "../../state/appReducers";

const Login = ({loggedIn, anonymous, pending = anonymous, children}) => {
  const user = useSelector(selectUser);
  const {studentId} = user || {};
  const {
    data: profile,
    pending: profilePending,
    error: profileError
  } = useCurrentProfile();
  const {t} = useTranslation();
  const error = (user && !studentId) || profileError;

  if (!error && profile && profile.studentUPId) {
    return loggedIn ? loggedIn({user, profile}) : children || <></>;
  } else {
    return (
      <>
        {profilePending && !error && pending && pending()}
        {(!profilePending && anonymous && anonymous()) || <></>}
        {
          <Message
            open={!!error}
            variant="error"
            message={t("Login.error.noMatchingProfile")}
            onClose={() => {
              authenticator.signOut();
            }}
          />
        }
      </>
    );
  }
};

export default Login;

Login.propsType = {
  /** Call this to render if logged in */
  loggedIn: PropTypes.func,
  /** Call this to render if not logged in */
  anonymous: PropTypes.func
};
