import {Box, Link, Typography} from "@material-ui/core";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import {withStyles} from "@material-ui/styles";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {SectionHeading} from "up-form";
import {Link as InternalLink} from "react-router-dom";

function LibraryPage({classes}) {
  const {t} = useTranslation();
  const items = t("LibraryPage.items", {returnObjects: true});
  function ExternalLink({href, children, ...other}) {
    return (
      <Link className={classes.link} href={href} target="_blank" rel="noreferrer noopener" {...other}>
        <Typography variant="subtitle1">
          {children}
          <ArrowRightAlt />
        </Typography>
      </Link>
    );
  }
  return (
    <Box pt={1}>
      <SectionHeading title={t("LibraryPage.title")} />
      <Typography>
        <Trans
          t={t}
          i18nKey="LibraryPage.intro"
          components={{
            myServicesLink: <InternalLink to="/case/support/general" />,
            contactLink: <Link href={"mailto:library@ichm.edu.au"} />
          }}
        />
      </Typography>
      {items.map(({title, links, text}, i) => {
        const heading = title || links[0].name;
        const linkList = links && links.map(({name, link}) => <ExternalLink href={link}>{name}</ExternalLink>);
        return (
          <section>
            {heading && <Typography variant="h3">{heading}</Typography>}
            {text && <Typography gutterBottom>{text}&nbsp;</Typography>}
            {linkList && linkList.map((link, j) => <Typography key={j}>{link}</Typography>)}
          </section>
        );
      })}
    </Box>
  );
}

export default withStyles({
  link: {
    "& svg": {
      verticalAlign: "middle"
    }
  }
})(LibraryPage);
