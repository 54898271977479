import React from "react";
import pure from "recompose/pure";
import { SvgIcon, useTheme } from "@material-ui/core";

/** A bullet to highlight 'recent' items in lists etc */
const Recent = pure((props) => {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      htmlColor={theme.palette.info.light}
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <circle cx="8" cy="8" r="4" />
      </g>
    </SvgIcon>
  );
});
Recent.displayName = "Recent";
Recent.muiName = "SvgIcon";
export default Recent;
