import React from "react";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {Message, useGlobal} from "up-form";
import UpWelcomePanel from "../up/UpWelcomePanel";
import Login from "../user/Login";
export const HomePage = () => {
  const {
      links: {forwardLogin}
    } = useGlobal(),
    {t} = useTranslation();
  return (
    <>
      <Login
        anonymous={() => <UpWelcomePanel />}
        pending={() => (
          <Message open variant="busy" message={t("Login.signingIn")} />
        )}
        loggedIn={({user, profile}) => {
          // The default login forward location
          console.log("redirecting " + forwardLogin);
          return forwardLogin ? (
            <Redirect to={forwardLogin} />
          ) : (
            <UpWelcomePanel />
          );
        }}
      />
    </>
  );
};
