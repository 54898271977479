import React from "react";
import {makeStyles, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import SimpleTable from "../table/SimpleTable";

const useStyles = makeStyles((theme) => ({
  subjectRow: {
    //backgroundColor: "transparent !important"
  },
  subjectCell: {
    verticalAlign: "top"
  },
  subjectTitle: {
    fontWeight: "bold"
  },
  moduleCell: {
    border: "none",
    padding: ".25em"
  }
}));

export default function Subjects({enrolments}) {
  const {t} = useTranslation();
  const {
    component: {MyEnrolmentsPage: {dateFormat = "LL"} = {}} = {},
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } = useGlobal();
  const classes = useStyles();
  function toTimezonedDate(v) {
    return v ? moment.tz(v, timezone) : undefined;
  }

  function Subject({name, startDate, endDate, enrolmentModuleList: modules}) {
    return (
      <div>
        <Typography className={classes.subjectTitle} variant="body1">
          {name}
        </Typography>
        <Table>
          <TableBody>
            {(modules || [])
              .sort(({index: a}, {index: b}) => a - b)
              .map(({name, moduleCode}, i) => (
                <TableRow key={i}>
                  <TableCell className={classes.moduleCell}>
                    <Typography>{moduleCode}</Typography>
                  </TableCell>
                  <TableCell className={classes.moduleCell}>
                    <Typography>{name}</Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    );
  }
  const subjects = (enrolments || []).flatMap(({enrolmentBlockList}) =>
    (enrolmentBlockList || []).map((block) => {
      const {startDate, endDate, censusDate, wnfDate, ...other} = block;
      return {
        ...other,
        startDate: toTimezonedDate(startDate),
        censusDate: toTimezonedDate(censusDate),
        wnfDate: toTimezonedDate(wnfDate),
        endDate: toTimezonedDate(endDate)
      };
    })
  );
  return (
    <>
      {subjects && (
        <SimpleTable
          classes={{cell: classes.subjectCell, row: classes.subjectRow}}
          itemName="Subject"
          emptyLabel={t("MyEnrolmentsPage.subjects.noSubjects")}
          rows={subjects}
          renderHeaderRow={() => ["Subject", "Start Date", "Census Date", "Withdrawal Date (WNF)", "End Date"]}
          renderRow={(subject) => {
            const {startDate, endDate, wnfDate, censusDate} = subject;
            return [
              <Subject {...subject} />,
              startDate && startDate.format(dateFormat),
              censusDate && censusDate.format(dateFormat),
              wnfDate && wnfDate.format(dateFormat),
              endDate && endDate.format(dateFormat)
            ];
          }}
          defaultSortColumn={"Start Date"}
          sort={{
            Subject: ({name: a}, {name: b}) => (a || "").localeCompare(b || ""),
            "Start Date": ({startDate: a}, {startDate: b}) => (a || 0) - (b || 0),
            "Census Date": ({censusDate: a}, {censusDate: b}) => (a || 0) - (b || 0),
            "Withdrawal Date (WNF)": ({wnfDate: a}, {wnfDate: b}) => (a || 0) - (b || 0),
            "End Date": ({endDate: a}, {endDate: b}) => (a || 0) - (b || 0)
          }}
          getCellProps={(column) => ({align: column > 0 ? "right" : "left"})}
        />
      )}
    </>
  );
}
