import {initAppInsights, appInsights} from "./AppInsights";
import {pickBy, identity, camelCase} from "lodash";
// Global id
var config = {
  segmentId: undefined,
  googleAnalyticsId: undefined,
  debug: 0 // 0=off 1=log page,track,identify 2=segment verbose logging
};

// Google Tag Manager
function gtag(...args) {
  if (config.googleAnalyticsId) window.dataLayer.push(...args);
}

export function init({
  appName,
  segmentId,
  googleAnalyticsId,
  history,
  debug,
  instrumentationKey // app insights key
}) {
  config = {
    segmentId,
    googleAnalyticsId,
    debug
  };
  if (segmentId) {
    // eslint-disable-next-line
    const ignore = !(function () {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on"
          ];
          analytics.factory = function (t) {
            return function () {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function (t, e) {
            var n = document.createElement("script");
            n.type = "text/javascript";
            n.async = !0;
            n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = "4.1.0";
          window.analytics.reset();
          window.analytics.load(segmentId);
          debug > 1 && window.analytics.debug();
          debug && console.debug("Analytics loaded");
        }
    })();
  }

  const query = new URLSearchParams(document.location.search);
  try {
    /**
     *
     * Capture the utm query paramaters from document url, if present. We do this in the same control context
     * as the initialisation call, as an SPA is likely to change history to a more appropriate location and lose these search params
     */
    const utmParams = [...query.entries()] // Merge in this page's utms, if any
      .filter(([k, _]) => /gclid|utm_(source|medium|campaign|term|content|ref)/.test(k))
      .reduce(
        (m, [k, v]) => ({...m, [k]: v}),
        JSON.parse(sessionStorage.getItem("utmParams")) || {} // init with existing params saved in session
      );
    sessionStorage.setItem("utmParams", JSON.stringify(utmParams)); // Re-save to sessionStorage
    if (googleAnalyticsId) {
      window.dataLayer = [utmParams]; // initialise dataLayer with them *before* GTM is even loaded to ensure page load gets them
    }
  } catch (e) {
    console.error("Unable to use session storage (maybeblocked in cognito mode");
  }

  if (googleAnalyticsId) {
    // Set some as cookies (just _ga atm) so GA sees this early on
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 2);
    ["_ga"]
      .filter((k) => query.has(k))
      .forEach((k) => (document.cookie = `${k}=${query.get(k)}; expires=${expires.toUTCString()}`));

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      });

      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f && f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", googleAnalyticsId);
  }

  if (instrumentationKey) {
    initAppInsights({
      appName,
      instrumentationKey,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true
    });
  }
  if (history) {
    page();
    history.listen((location) => {
      page();
    });
  }
}

export function page(category, name, properties) {
  const {segmentId, googleAnalyticsId, debug} = config;
  debug &&
    console.debug(`Analytics Page - Segment: ${segmentId || "(disabled)"}, GA: ${googleAnalyticsId || "(disabled)"}`, {
      category,
      name,
      properties
    });
  segmentId && window.analytics.page(category, name, properties);
  googleAnalyticsId &&
    gtag({
      event: "virtualPageView",
      page_category: category,
      page_name: name,
      ...properties
    });
  appInsights && appInsights.trackPageView({name, pageType: category}, properties);
}

export function track(event, properties) {
  const {segmentId, debug} = config;
  const cleanProperties = properties && pickBy(properties, identity); // remove 'undefined/null'
  segmentId && window.analytics.track(event, cleanProperties);
  appInsights && appInsights.trackEvent({name: event}, cleanProperties);
}

export function identify(userId, traits) {
  const {segmentId, debug, googleAnalyticsId} = config;
  debug &&
    console.debug(`Segment Identify ${segmentId || "(disabled)"}`, {
      userId,
      traits
    });
  googleAnalyticsId && gtag({event: "login", userId, ...traits});
  segmentId && window.analytics.identify(userId, traits);
}

export function trackError(error, properties) {
  appInsights && appInsights.trackException({error, severity: 3}, properties);
}

/**
 * NOte keys are converted to camelcase
 * @returns objectt containing utm paramaters as stored in sessionStorage
 */
export function getUtmParams() {
  const utmParamsStr = sessionStorage.getItem("utmParams");
  return utmParamsStr && Object.fromEntries(Object.entries(JSON.parse(utmParamsStr)).map(([k, v]) => [camelCase(k), v]));
}