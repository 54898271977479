import React from "react";
import {Typography, Box, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useGlobal} from "up-form";
import {useTranslation} from "react-i18next";
import SignInButton from "../user/SignInButton";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundImage: props.image,
    backgroundPosition: "top center",
    backgroundSize: "cover",
    height: "80vh"
  }),
  tag: {
    backgroundColor: theme.palette.secondary.light,
    "& .MuiTypography-root": {
      color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      top: "40%",
      left: "10%",
      textAlign: "center",
      width: "25em"
    }
  },
  welcomeText: {
    paddingBottom:20
  }
}));

const UpWelcomePanel = ({busy}) => {
  const theme = useTheme(),
    {welcomeBg} = useGlobal(),
    classes = useStyles({
      image: `url(${welcomeBg})`
    }),
    {t} = useTranslation(),
    compact = useMediaQuery(theme.breakpoints.down("xs"));

  const tag = (
    <Box className={classes.tag} p={2}>
      <Typography variant="h1">
        {t("upwelcomepanel.tag.title")}
      </Typography>
      <Typography variant="body1" className={classes.welcomeText}>
        {t("upwelcomepanel.tag.text")}
      </Typography>
      <SignInButton busy={busy} />
    </Box>
  );

  return (
    <>
      <Box className={classes.root}>{!compact && tag}</Box>
      {compact && tag}
    </>
  );
};

export default UpWelcomePanel;
