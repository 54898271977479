import {Auth} from "@aws-amplify/auth";
import {Box, Button, CircularProgress, Grid, Snackbar, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {reduxForm, SubmissionError} from "redux-form";
import {length, required, validateForm} from "redux-form-validators";
import {SectionHeading, TextField, useGlobal} from "up-form";
import SkeletonGrid from "../SkeletonGrid";

const passwordRules = [required(), length({min: 8})];
const allTextProps = {fullWidth: true, notRequiredText: ""};
export const SecurityForm = reduxForm({
  form: "security",
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  },
  validate: validateForm({
    currentPassword: passwordRules,
    newPassword: passwordRules,
    confirmNewPassword: passwordRules.concat([
      (value, [allValues]) => {
        console.log(allValues);
        return value !== allValues.newPassword ? "Passwords don't match" : undefined;
      }
    ])
  })
})(({profile, submitting, dirty, error, invalid, handleSubmit, reset, submitSucceeded}) => {
  const {t} = useTranslation();
  const [saveStatus, setSaveStatus] = useState(),
    {succeeded} = saveStatus || {};
  const {preview} = useGlobal();

  return (
    <form
      onSubmit={handleSubmit((f) =>
        Auth.currentAuthenticatedUser().then((user) =>
          Auth.changePassword(user, f.currentPassword, f.newPassword)
            .then((res) => {
              setSaveStatus({succeeded: true});
              reset();
            })
            .catch((e) => {
              setSaveStatus({succeeded: false});
              throw new SubmissionError({_error: e.message});
            })
        )
      )}
    >
      <SectionHeading title={t("ProfileForm.security.title")} />

      <Box m={2} mb={3}>
        {error && (
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
        )}
        {preview ? (
          <SkeletonGrid rows={3} />
        ) : (
          <Grid container spacing={2} margin={2}>
            <Grid item sm={4}>
              <TextField
                type="password"
                name="currentPassword"
                label={t("ProfileForm.security.currentPassword.label")}
                autoComplete="off"
                {...allTextProps}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                type="password"
                name="newPassword"
                label={t("ProfileForm.security.newPassword.label")}
                autoComplete="off"
                {...allTextProps}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                type="password"
                name="confirmNewPassword"
                label={t("ProfileForm.security.confirmNewPassword.label")}
                autoComplete="off"
                {...allTextProps}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      {dirty && (
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button disabled={!dirty} variant="contained" onClick={reset}>
              {t("ProfileForm.reset.label")}
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={submitting || !dirty || invalid} variant="contained" type="submit" color="primary">
              {submitting && <CircularProgress size="1em" style={{marginRight: "1em"}} />}{" "}
              {t("ProfileForm.security.changePassword.label")}
            </Button>
          </Grid>
        </Grid>
      )}
      <Snackbar autoHideDuration={6000} open={!!saveStatus} onClose={() => setSaveStatus(null)}>
        <Alert severity={succeeded ? "success" : "error"}>
          {t(`ProfileForm.security.${succeeded ? "passwordChangeSucceeded" : "passwordFaileded"}.text`, {profile})}
        </Alert>
      </Snackbar>
    </form>
  );
});
