/**
 * Module for using AppInsights from react hook apps, with React-Router SPA page naviigation
 */
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {Envelope} from "@microsoft/applicationinsights-common";

/**
 * global appInsights objects
 */
export const reactPlugin = new ReactPlugin();
export var appInsights = undefined; // available after init

export function initAppInsights({appName, instrumentationKey, history}) {
  if (instrumentationKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {history: history}
        },
        enableUnhandledPromiseRejectionTracking: true,
        enableRequestHeaderTracking: true,
        disableFetchTracking: true
      }
    });
    appInsights.loadAppInsights();
    appName &&
      appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags["ai.cloud.role"] = appName;
      });
    console.log("AppInsights logging to ", instrumentationKey);
  } else {
    console.log("AppInsights not initialised");
  }
}
