/**
 * Menu for selecting learning managment system link for enroleed courses.
 * If there is only one enroled course works as a link to that course
 */
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import {useEnrolments} from "up-state";
import {useCurrentProfile} from "../../state/appReducers";

const LmsMenu = () => {
  const {t} = useTranslation();
  const {
    data: {studentUPId},
    pending: profilePending
  } = useCurrentProfile();
  const {
    links: {lmsCourse}
  } = useGlobal();
  const {data: enrolments, pending: enrolmentsPending} = useEnrolments(studentUPId);
  const multipleEnrolments = Array.isArray(enrolments) && enrolments.length > 1;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const pending = enrolmentsPending || profilePending;
  function redirect({courseCode}) {
    const url = `${lmsCourse}/?course=${courseCode}&upId=${studentUPId}`;
    console.log(`Returning to LMS, redirecting to ${url}`);
    window.location.href = url;
  }
  const theme = useTheme(),
    isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  function click({currentTarget}) {
    if (Array.isArray(enrolments)) {
      if (multipleEnrolments) {
        setAnchorEl(currentTarget);
      } else {
        redirect(enrolments[0]);
      }
    }
  }
  const arrow = <ArrowRightAlt style={{transform: "rotate(180deg)"}} />;

  return (
    <>
      {enrolments && (
        <Menu id="lms-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          {enrolments.map((enrolment, i) => (
            <MenuItem key={i} onClick={() => redirect(enrolment)}>
              <ListItemIcon>{arrow}</ListItemIcon>
              <Typography variant="inherit" style={{whiteSpace: "normal"}}>
                {enrolment.courseName}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}

      {pending ? (
        <CircularProgress size="1em" />
      ) : (
        <Tooltip title={t("LmsMenu.help")}>
          <IconButton color="primary" onClick={click} style={{whiteSpace: "normal"}}>
            {arrow}
            {!isMobile && t("LmsMenu.label")}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default LmsMenu;
