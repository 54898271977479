import {Accordion, AccordionDetails, AccordionSummary, Chip, Grid, makeStyles, Typography} from "@material-ui/core";
import {green, grey, red, yellow} from "@material-ui/core/colors";
import {darken} from "@material-ui/core/styles/colorManipulator";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment-timezone";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobal} from "up-form";
import Recent from "../icons/Recent";
import SimpleTable from "../table/SimpleTable";

const useStyles = makeStyles((theme) => ({
  submission: {
    "& .MuiTypography-displayInline:after": {
      content: "'\\00a'"
    }
  },
  courseName: {},
  courseCode: {},
  blockName: {},
  moduleName: {
    fontWeight: "500"
  },
  attempts: {},
  accordionRoot: {
    background: "transparent"
  },
  accordionSummaryRoot: {
    flexDirection: "row-reverse"
  },
  expandIcon: {
    marginRight: 0,
    marginLeft: "-1rem"
  },
  resultItem: {
    margin: "0 1em .5em auto",
    minWidth: "10em"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: darken(theme.palette.background.paper, 0.2)
    },
    "&:nth-of-type(even)": {
      backgroundColor: darken(theme.palette.background.paper, 0.1)
    }
  },
  cell: {
    borderWidth: "1rem"
  }
}));

const Submissions = ({enrolments}) => {
  const {t} = useTranslation();
  const {
    component: {
      Submissions: {pageSize = 10, recentPeriod = "PT24H"}
    },
    timezone
  } = useGlobal();
  const classes = useStyles();
  const now = moment.tz(timezone).startOf("day");
  const recent = now.clone().subtract(moment.duration(recentPeriod));

  /**
   * A humanized diff of days - as we only have dates (no time) for submission etc we can only go
   * from
   */
  function fromNowDays(d) {
    return now.isSame(d, "day") ? "Today" : d.from(now, false);
  }
  function toTimezonedDate(v) {
    return v ? moment.tz(v, timezone) : undefined;
  }
  /**
   * Map backend status info to user-oriented status
   * @param {} param0
   * @returns
   */
  function resultStatus({status, externalGrade}) {
    const wasGraded = ["Resubmission", "Grading Complete", "Completed"].includes(status);
    return {
      submitted: !["Opened"].includes(status), // Can be displayed as a submitted attempt
      graded: wasGraded, // has been graded
      pending: !(wasGraded && !!externalGrade), // hasn't been graded yet or we don't have the result yet
      complete: wasGraded && !!externalGrade, // we have the grade
      incomplete: status === "Incomplete",
      submissionError: status === "Submission Error"
    };
  }

  // Flatten into a single list of assessments outer joined with course,block, module
  // Filter out assessments with no valid attempts etc and fix up API inconsistencies
  const submissions =
    enrolments &&
    enrolments
      .flatMap((course) =>
        (course.enrolmentBlockList || []).flatMap((block) =>
          (block.enrolmentModuleList || []).flatMap((module) =>
            (module.enrolmentModuleAssessmentList || []).map((assessment) => ({
              course,
              block,
              module: {...module, startDate: toTimezonedDate(module.startDate)},
              assessment: {
                ...assessment,
                assessmentAttempts: (assessment.assessmentAttempts || {})
                  .map((attempt) => ({
                    ...attempt,
                    status: attempt.statusCode, // FIX API: mismatch - statusCode is text
                    success: attempt.isSuccess === "Yes", // FIX API: mismatch boolean vs yes no
                    submissionDate: toTimezonedDate(attempt.submissionDate),
                    gradedDate: toTimezonedDate(attempt.gradedDate)
                  }))
                  .filter((attempt) => resultStatus(attempt).submitted) // ignore non submitted
                  .filter(({attemptNumber}) => !isNaN(attemptNumber)) // attempt number must be defined
                  .sort(({attemptNumber: a}, {attemptNumber: b}) => b - a),
                submissionDate: toTimezonedDate(assessment.submissionDate),
                gradedDate: toTimezonedDate(assessment.gradedDate)
              }
            }))
          )
        )
      )
      .filter(
        ({assessment: {assessmentAttempts} = {}}) => Array.isArray(assessmentAttempts) && assessmentAttempts.length > 0 // at least one legal,submitted attempt must be present
      );
  function Submission({
    course: {courseName, courseCode},
    module: {index: moduleIndex, name: moduleName, moduleCode, startDate},
    assessment: {name: assessmentName, assessmentCode, assessmentAttempts = []}
  }) {
    // Sort Attempts list into descending attemptNumber order, filter any that have number
    // Recent if any attempts have recent submission/graded dates
    const isRecent = assessmentAttempts.find(({gradedDate, submissionDate}) =>
      [gradedDate, submissionDate].find((d) => !!d && d.isBefore(now) && d.isAfter(recent))
    );
    // Headline result is the first attempt that has a grade or just the current pending/incomplete attempt if none
    const headlineResult =
      assessmentAttempts.find((attempt) => {
        const {complete} = resultStatus(attempt);
        return complete;
      }) || assessmentAttempts[0];

    function Attempt({name, submissionDate, gradedDate, success, externalGrade, status, size}) {
      return (
        <Grid item key={name} container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2" className={classes.assessment}>
              {name}
            </Typography>
          </Grid>
          {submissionDate && (
            <Grid item>
              <Typography variant="subtitle2" className={classes.submission}>
                {t("Submissions.table.submittedDate", {
                  since: fromNowDays(submissionDate)
                })}
              </Typography>
            </Grid>
          )}
          {gradedDate && (
            <Grid item>
              <Typography variant="subtitle2" className={classes.graded}>
                {t("Submissions.table.gradedDate", {
                  since: fromNowDays(gradedDate)
                })}
              </Typography>
            </Grid>
          )}
          <Grid item style={{marginLeft: "auto"}}>
            <Result {...{success, externalGrade, status, size}} />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item container direction="column" className={classes.submission}>
          <Grid item>
            {isRecent && <Recent />}
            <Typography display="inline" variant="subtitle1" className={classes.courseName}>
              {courseName}
            </Typography>
            <Typography display="inline" variant="subtitle2" className={classes.courseCode}>
              {courseCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography display="inline" variant="subtitle1" className={classes.moduleName}>
              {moduleName}
            </Typography>
            <Typography display="inline" variant="subtitle2" className={classes.moduleCode}>
              {moduleCode}
            </Typography>
          </Grid>
          {startDate && (
            <Grid item>
              <Typography display="inline" variant="subtitle1" className={classes.startDate}>
                {t("Submissions.table.startDate", {
                  startDate: startDate.format("LL")
                })}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {assessmentName && (
              <Typography display="inline" variant="subtitle1" className={classes.assessment}>
                {t("Submissions.table.assessment", {
                  index: moduleIndex,
                  name: assessmentName
                })}
              </Typography>
            )}
            {assessmentCode && (
              <Typography display="inline" variant="subtitle2" className={classes.assessmentCode}>
                {assessmentCode}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Result {...headlineResult} size="large" />
        <Grid item xs={12}>
          <Accordion elevation={0} variant="outlined" classes={{root: classes.accordionRoot}}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDown />}
              classes={{
                expandIcon: classes.expandIcon,
                root: classes.accordionSummaryRoot
              }}
            >
              <Typography gutterBottom={false} variant="subtitle2" className={classes.attempts}>
                {t("Submissions.attempts.label", {
                  count: assessmentAttempts.length
                })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column" style={{width: "100%"}}>
                {assessmentAttempts.map((attempt, i) => (
                  <Attempt
                    key={i}
                    name={t("Submissions.table.attemptNumber", {
                      index: attempt.attemptNumber
                    })}
                    size="small"
                    {...attempt}
                  />
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  }
  /**
   * A Result chip
   */
  function Result({success, externalGrade, status, size}) {
    const {complete, pending, incomplete, submissionError} = resultStatus({
      status,
      externalGrade
    });
    const colour = submissionError ? red[500] : incomplete ? yellow[500] : pending ? grey[500] : success ? green[500] : yellow[500];
    const text =
      (complete && externalGrade) ||
      t(`Submissions.table.${incomplete ? "incomplete" : submissionError ? "submissionError" : "pendingGrade"}`);
    return <Chip className={classes.resultItem} style={{backgroundColor: colour}} label={text} size={size} />;
  }
  return (
    <>
      {submissions && (
        <SimpleTable
          classes={{cell: classes.cell, row: classes.row}}
          defaultSortColumn="Module Start Date"
          itemName="Submission"
          emptyLabel={t("Submissions.noGrades")}
          rows={submissions}
          renderRow={(row) => [<Submission {...row} />]}
          pageSize={pageSize}
          getCellProps={(column) => ({align: column > 0 ? "right" : "left"})}
          sort={{
            "Course Name": ({course: {courseName: a}}, {course: {courseName: b}}) => (a || "").localeCompare(b || ""),
            "Module Name": ({module: {name: a}}, {module: {name: b}}) => (a || "").localeCompare(b || ""),
            "Module Start Date": ({module: {startDate: a}}, {module: {startDate: b}}) => (a || 0) - (b || 0),
            "Assessment Name": ({assessment: {name: a}}, {assessment: {name: b}}) => (a || "").localeCompare(b || ""),
            "Submission date": ({assessment: {submissionDate: a}}, {assessment: {submissionDate: b}}) => (a || 0) - (b || 0),
            "Graded Date": ({assessment: {gradedDate: a}}, {assessment: {gradedDate: b}}) => (a || 0) - (b || 0),
            Attempts: ({assessment: {assessmentAttempts: a = []}}, {assessment: {assessmentAttempts: b = []}}) =>
              a.length - b.length
          }}
        />
      )}
    </>
  );
};

export default Submissions;
