/**
 * Authentication actions - provide a an authentication agnostic API
 */

import * as msal from "@azure/msal-browser";

import Amplify, { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";

export type LoggedInUser = {
	studentId: string,
	idToken: string,
	idTokenClaims: object
}
export type LoginHandler = (source: Authenticator, state: object, user: LoggedInUser) => void
export type LogoutHandler = (source: Authenticator) => void
export type InitOptions = {
	handleLogin?: LoginHandler,
	handleLogout?: LogoutHandler
}
type MaslInitOptions = {
	msalConfig: {
		config: msal.Configuration,
		loginRequest: { scopes?: Array<string> }
		tokenRequest: { scopes?: Array<string> }
	}
} & InitOptions;

type AmplifyInitOptions = { amplifyConfig: object } & InitOptions;


export type SignInOptions = {
	forwardTo?: string
}
export type SignOutOptions = {
	forwardTo?: string
}
export type Tokens = {
	accessToken: string,
	idToken: string
}

export interface Authenticator {
	/**
	 * True if is currently logged in
	 */
	isLoggedIn: boolean,
	/**
	 * True if is currently redirecting for auth flow (may want to delay renders to avoid scrren flash)
	 */
	isRedirecting: boolean,
	signIn(options?: SignInOptions): Promise<void>,
	signOut(options?: SignOutOptions): Promise<void>,
	fetchTokens(): Promise<Tokens>
}

function isRedirectingOAuth(): boolean {
	const urlParams = new URLSearchParams(document.location.search)
	return !!urlParams.get("code")
}

class AuthAWS implements Authenticator {
	isLoggedIn: boolean;
	config: any;
	async init(): Promise<void> {
		try {
			await Auth.currentAuthenticatedUser({ bypassCache: false })
		}
		catch (error) {
			console.debug("No cached login found")
		}
	}
	constructor({ amplifyConfig, handleLogin, handleLogout }: AmplifyInitOptions) {
		this.isLoggedIn = false;
		this.config = amplifyConfig;
		Amplify.configure(amplifyConfig);
		Hub.listen("auth", async ({ payload: { event, message } }) => {
			switch (event) {
				case "SignIn":
				case "cognitoHostedUI":
					this.isLoggedIn = true;
					if (handleLogin) {
						const session = await Auth.currentSession()
						const idToken = session.getIdToken()
						const { payload } = idToken
						const { payload: { username: studentId } = {} } = session.getAccessToken()
						let state = undefined
						try {
							const stateEncoded = localStorage.getItem("oauth_state")
							const stateDecoded = stateEncoded && JSON.parse(stateEncoded)
							state = typeof stateDecoded === 'object' && stateDecoded; // this app always sets an object or nothing 
						}
						catch (error) {
							// we only want to handle json state so leave undefined
						}
						handleLogin(this, { ...state }, { studentId, idToken: idToken.getJwtToken(), idTokenClaims: payload });
					}
					break;
				case "SignOut":
					handleLogout && handleLogout(this);
					break;
				default:
					break;
			}
			console.log("A new auth event has happened: ", event, message);
		});
		this.init()
	}

	get isRedirecting() {
		return !this.isLoggedIn && isRedirectingOAuth()
	}

	/**
	 *
	 * @param {*} param0
	 * @returns Promise({accessToken,idToken})
	 */
	async signIn(signinOptions: SignInOptions): Promise<void> {
		// Cognito login
		// Bug in AWS amplify (https://github.com/aws-amplify/amplify-js/issues/3054),
		// currently attempts to store state in sessionStorage which gets clearED on hosted ui redirect is not found
		// we save in localStorage in SignInAction
		if (signinOptions) window.localStorage.setItem("oauth_state", JSON.stringify(signinOptions));
		else window.localStorage.removeItem("oauth_state");


		Auth.federatedSignIn()
	}

	async signOut(): Promise<void> {
		return Auth.signOut({ global: true }) as Promise<void>
	}

	/**
	 *
	 * @returns Promise({accessToken,idToken})
	 */
	async fetchTokens(): Promise<Tokens> {
		console.assert(this.isLoggedIn)
		const userSession = await Auth.currentSession();
		return { accessToken: userSession.getAccessToken().getJwtToken(), idToken: userSession.getIdToken().getJwtToken() };
	}
}

class AuthMSAL implements Authenticator {
	account?: msal.AccountInfo;
	isLoggedIn: boolean;
	msalConfig: any;
	msalInstance: msal.PublicClientApplication;
	options: MaslInitOptions;

	async init(): Promise<void> {
		if (!this.isLoggedIn) {
			const tokenResponse = await this.msalInstance.handleRedirectPromise();
			const accounts = this.msalInstance.getAllAccounts();
			if (tokenResponse && accounts.length > 0) {
				this.account = accounts[0];
				this.isLoggedIn = true;
				const { idToken, idTokenClaims, idTokenClaims: { samAccountName: studentId = "1234" }, state: stateEncoded
				} = tokenResponse as any
				let state = undefined
				try {
					const stateDecoded = stateEncoded && JSON.parse(stateEncoded)
					state = typeof stateDecoded === 'object' && stateDecoded; // this app always sets an object or nothing 
				}
				catch (error) {
					// we only want to handle json state so leave undefined
				}
				this.options.handleLogin && this.options.handleLogin(this, { ...state }, { studentId, idToken, idTokenClaims });
			}
		}

	}
	constructor(options: MaslInitOptions) {
		this.options = options
		this.isLoggedIn = false;
		this.msalInstance = new msal.PublicClientApplication(this.options.msalConfig.config);
		this.init()
	}

	get isRedirecting() {
		return !this.isLoggedIn && isRedirectingOAuth()
	}

	/**
	 * 
	 * @param state 
	 * @returns Promise<Void>
	 */
	async signIn(options: SignInOptions): Promise<void> {
		const { loginRequest, tokenRequest } = this.options.msalConfig;
		await this.init()
		if (!this.isLoggedIn) this.msalInstance.loginRedirect({
			state: JSON.stringify(options),
			scopes: [...loginRequest.scopes || [], ...tokenRequest.scopes || []]
		});
	}

	/**
	 *
	 * @param {*} param0
	 * @returns Promise(any)
	 */
	async signOut(): Promise<void> {
		await this.init()
		this.isLoggedIn && this.msalInstance.logoutRedirect({ account: this.account });
	}

	/**
	 *
	 * @param {*} param0
	 * @returns Promise({accessToken,idToken})
	 */
	async fetchTokens(): Promise<Tokens> {
		await this.init()
		const accounts = this.msalInstance.getAllAccounts()
		console.assert(this.isLoggedIn && accounts.length > 0)
		const { tokenRequest: { scopes = [] } = {} } = this.options.msalConfig;
		const { accessToken, idToken } = await this.msalInstance.acquireTokenSilent({
			account: accounts[0],
			scopes
		});
		return { accessToken, idToken };
	}
}

class MockAuthenticator implements Authenticator {
  options: InitOptions;
  isLoggedIn: boolean;
  isRedirecting: boolean;
  mockUser: LoggedInUser;
  constructor(options: InitOptions) {
    this.isLoggedIn = false;
    this.isRedirecting = false;
    this.options = options;
    this.mockUser = {idToken: "Mock-Id-Token", studentId: "Mock-Student-Id", idTokenClaims: {}};
  }
  signIn(options?: SignInOptions | undefined): Promise<void> {
    return new Promise((resolve) =>
      setTimeout(() => this.options.handleLogin && this.options.handleLogin(this, {}, this.mockUser))
    );
  }
  signOut(options?: SignOutOptions | undefined): Promise<void> {
    return Promise.resolve();
  }
  fetchTokens(): Promise<Tokens> {
    return Promise.resolve({accessToken: "Mock-Access-Token", idToken: this.mockUser.idToken});
  }
}
/**
 * One global authenticator
 */
var theAuthenticator: Authenticator = {} as Authenticator;
export {theAuthenticator as default};

export function authenticatorInit(options: InitOptions | AmplifyInitOptions | MaslInitOptions) {
  const amplifyOptions = options as AmplifyInitOptions;
  const msalOptions = options as MaslInitOptions;
  if (amplifyOptions.amplifyConfig) {
    theAuthenticator = new AuthAWS(amplifyOptions);
  } else if (msalOptions.msalConfig) {
    theAuthenticator = new AuthMSAL(options as MaslInitOptions);
  } else {
    theAuthenticator = new MockAuthenticator(options);
  }
  console.log(theAuthenticator);
}

