import {Action} from "redux";
import {track} from "./Analytics";

/**
 * Redux middleware to track form field changes for analytics
 */
export function trackerMiddleware({whitelist, blacklist}) {
  function mapAction(reduxAction) {
    const {type, ...payload} = reduxAction;
    if (type) {
      if ((whitelist && whitelist.test(type)) || !blacklist || !blacklist.test(type))
        return {
          event: type,
          properties: {
            category: "Redux",
            ...payload
          }
        };
    }
    return undefined; // unrecognised or filtered out
  }
  return (store) => (next) => (action) => {
    const {event, properties} = mapAction(action) || {};
    if (event) track(event, properties);
    return next(action);
  };
}
