import {ListItem, ListItemIcon, ListItemText, withStyles} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useGlobal} from "up-form";
import {Optional} from "up-form";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.selected
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    },
    "& .MuiListItemIcon-root": {
      fontSize: "large",
      minWidth: "2em"
    }
  }
}))(MenuItem);

export default function PopupMenu({anchorEl, open, onClose, menu}) {
  const {t} = useTranslation(),
    handleClose = () => onClose && onClose();
  const {links} = useGlobal();
  return (
    <>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <ListItem color="secondary" divider>
          <ListItemText>{t(`${menu.name}.title`)}</ListItemText>
        </ListItem>
        {(menu.items || []).map(({href, to, icon, name}, i) => {
          const externalLink = href && links[href];
          return (
            <Optional key={i} name={`${menu.name}.${name}`}>
              <StyledMenuItem
                component={NavLink}
                href={externalLink}
                to={(!externalLink && to) || undefined}
                color="textPrimary"
                onClick={handleClose}
                target={externalLink && "_blank"}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{t(`${menu.name}.${name}.label`)}</ListItemText>
              </StyledMenuItem>
            </Optional>
          );
        })}
      </Menu>
    </>
  );
}

PopupMenu.propTypes = {
  anchorEl: PropTypes.any,
  menu: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon: PropTypes.node,
        href: PropTypes.string, // URL or global links key
        to: PropTypes.string // router path
      })
    )
  }),
  onClose: PropTypes.func,
  open: PropTypes.any
};
