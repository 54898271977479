import {Box, Typography, withStyles} from "@material-ui/core";
import {themedMarkup} from "../../themes";

const ContentPage = withStyles(themedMarkup)(({classes, children, ...other}) => {
  return (
    <Box p={1}>
      <Typography className={classes.root} classes component={"div"} variant="body1" {...other}>
        {children}
      </Typography>
    </Box>
  );
});

export default ContentPage;
