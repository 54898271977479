import {Box, Link, Typography, withStyles} from "@material-ui/core";
import {Link as InternalLink} from "react-router-dom";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Message, SectionHeading} from "up-form";
import {useEnrolments} from "up-state";
import {selectUser} from "../../state/appReducers";
import Subjects from "./Subjects";
import Grades from "./Grades";

/**
 * The MyEnrolments page is the ichm (initially) version of the grades page
 * @returns
 */
function MyEnrolmentsPage({classes}) {
  const {t} = useTranslation();
  const {studentId} = useSelector(selectUser) || {};
  const {data: enrolments, error} = useEnrolments(studentId);
  function ExternalLink({href, children, ...other}) {
    return (
      <Link className={classes.link} href={href} target="_blank" rel="noreferrer noopener" underline="always" {...other}>
        {children}
      </Link>
    );
  }
  return (
    <Box p={1}>
      <Typography variant="h1">{t("MyEnrolmentsPage.title")}</Typography>
      <SectionHeading title={t("MyEnrolmentsPage.subjects.title")} />
      <Typography component={"div"}>
        <Trans
          t={t}
          i18nKey="MyEnrolmentsPage.subjects.intro"
          components={{
            enrolmentLink: <ExternalLink href="https://www.ichm.edu.au/future-students/fees-and-payment/fees-2022" />,
            myServicesLink: <InternalLink to="/case/support/general" />
          }}
        />
      </Typography>
      <Subjects enrolments={enrolments} />
      <SectionHeading title={t("MyEnrolmentsPage.grades.title")} />
      <Typography>
        <Trans t={t} i18nKey="MyEnrolmentsPage.grades.intro" components={{}} />
      </Typography>
      <Grades enrolments={enrolments} />
      <Typography variant="h3">{t("MyEnrolmentsPage.grades.footnote.title")} </Typography>
      <Trans
        t={t}
        i18nKey="MyEnrolmentsPage.grades.footnote.text"
        components={{
          resultsPolicyLink: (
            <ExternalLink href="https://assets.ctfassets.net/s58hoc3j8a24/5iVyFQOAECnV2F3gC55Ktw/4402ad4c157b043aa8689172d3dc0163/Results__Grades_and_Awards.pdf" />
          ),
          grievenceLink: (
            <ExternalLink href="https://assets.ctfassets.net/s58hoc3j8a24/61dYn3q43kPcPaIJgdla1a/abbff6623fea37f5f7afca67247ec094/Academic_Grievance.pdf" />
          ),
          templateLink: (
            <ExternalLink href="https://cms-uped.baseux.com/sites/default/files/Stage%201%20-%20Appeal%20to%20Academic%20Committee%20Template%20(1).docx" />
          )
        }}
      />
      <Typography variant="h3">{t("MyEnrolmentsPage.footnote.title")} </Typography>
      <Typography gutterBottom variant="body1" component={"div"}>
        <Trans
          t={t}
          i18nKey="MyEnrolmentsPage.footnote.text"
          components={{myServicesLink: <InternalLink to="/case/support/general" />}}
        />
      </Typography>
      {error && <Message variant="error" message={error.message} open />}
    </Box>
  );
}

export default withStyles({
  link: {}
})(MyEnrolmentsPage);
