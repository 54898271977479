import {green, grey, orange, red} from "@material-ui/core/colors";
import {responsiveFontSizes} from "@material-ui/core";
import {createTheme} from "@material-ui/core/styles";
import {merge} from "lodash";
import {lighten, darken} from "@material-ui/core/styles/colorManipulator";
import {white} from "@material-ui/core/colors/common";
const defaultPalette = {
  success: green,
  warning: orange,
  error: red,
  info: {main: "#FF8B4C"}
};

const primary = {
    main: "#f97a62"
  },
  secondary = {
    main: "#eeeeee"
  },
  paper = "#f6f6f9",
  allHeaders = {
    fontWeight: "500",
    lineHeight: 2
  };

// A root theme shared by all child pages
const rootDefaults = {
  typography: {
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),

    // Enforcing sizing here to better match the less responsive sizing in LMS
    useNextVariants: true,
    h1: {...allHeaders, fontSize: "32px", textTransform: "none"},
    h2: {...allHeaders, fontSize: "24px"},
    h3: {...allHeaders, fontSize: "20px"},
    h4: {...allHeaders, fontSize: "16px"},
    h5: {...allHeaders, fontSize: "14px"},
    h6: {...allHeaders, fontSize: "12px"},
    body1: {fontSize: "12px"},
    body2: {fontSize: "12px"},
    caption: {fontSize: "12px"},
    subtitle1: {
      fontSize: "12px",
      textTransform: "none",
      fontWeight: 400
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: "300",
      textTransform: "none"
    }
  },
  overrides: {
    // default styles for Mui components
    MuiCardHeader: {
      title: {
        fontSize: "1.5rem"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em"
      },
      label: {
        whiteSpace: "nowrap"
      }
    },
    MuiTypography: {
      h4: {
        borderBottom: "solid #eee 1px",
        paddingBottom: "1rem",
        marginBottom: "1rem"
      },
      "& dt": {
        flexBasis: "20%",
        textAlign: "left",
        padding: "2px 4px",
        fontWeight: "bold"
      },
      "& dd": {
        flexBasis: "70%",
        flexGrow: 1,
        margin: 0,
        padding: "2px 4px"
      },
      "& dl": {
        display: "flex",
        flexFlow: "row wrap"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: paper
      }
    },
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiTextField: {
      root: {
        "& input[readonly]": {
          backgroundColor: grey[300] // so readonly fields look different to editable
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    }
  },
  palette: {
    primary: primary,
    secondary: secondary,
    background: {
      paper: "#ffffff",
      default: "#f6f6f9"
    }
  },
  status: {
    success: "green",
    danger: "red"
  }
};
const rootTheme = createTheme(rootDefaults);

const themeByProvider = {
  soe: {
    typography: {
      fontFamily: ["Oswald", "Tahoma", "sans-serif"].join(",")
    },
    palette: {
      ...defaultPalette,
      primary: {
        main: "#000000",
        contrastText: "#fff"
      },
      secondary: {
        main: "#F2EBE1" //#DC2D27"
      }
    },
    overrides: {
      MuiLink: {
        root: {
          "&.MuiTypography-colorPrimary": {
            color: "#006A94",
            "&:hover": {
              color: "#dc2d27"
            }
          },
          "&.MuiTypography-colorPrimary.MuiButton-root": {
            color: "#fff"
          }
        }
      },
      MuiButton: {
        root: {
          borderRadius: "0px",
          fontWeight: "bold"
        }
      }
    }
  },
  iahealth: {
    typography: {
      fontFamily: "BasisGrotesquePro"
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {
        main: "#725a91"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "BasisGrotesquePro",
              src: 'url("/providers/iahealth/Basis-Grotesque-Pro-Regular.woff2") format("woff2")',
              fontWeight: 500
            }
          ]
        }
      },
      MuiButton: {
        root: {
          borderRadius: "5px",
          fontWeight: 500
        },
        containedPrimary: {
          backgroundColor: "#50bd9c",
          "&:hover": {
            backgroundColor: darken("#38ba93", 0.25)
          }
        }
      }
    }
  },
  ichm: {
    typography: {
      fontFamily: "GraphickWide",
      h1: {fontFamily: "GraphickWideBold", color: "#000070", textTransform: "uppercase"},
      h2: {fontFamily: "GraphickWideBold", color: "#000070"},
      h3: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h4: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h5: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h6: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      body1: {fontFamily: "Graphick"},
      body2: {fontFamily: "Graphick"},
      caption: {fontFamily: "Graphick"},
      subtitle1: {fontFamily: "Graphick"},
      subtitle2: {fontFamily: "Graphick"}
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {main: "#000070"},
      secondary: {main: "#ED4036"},
      text: {primary: "#000000", secondary: "#A6A6A6"},
      background: {
        default: white,
        paper: white
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "GraphickWideBold",
              src: 'url("/providers/ichm/GraphikWide-Bold-Web.woff2") format("woff2")'
            },
            {
              fontFamily: "GraphickWide",
              src: 'url("/providers/ichm/GraphikWide-Regular-Web.woff2") format("woff2")'
            },
            {
              fontFamily: "Graphick",
              src: 'url("/providers/ichm/Graphik-Regular-Web.woff2") format("woff2")'
            }
          ]
        }
      }
    }
  },
  "nz-institute-of-sport": {
    typography: {
      fontFamily: "Open Sans",
      h1: {fontFamily: "Industry", color: "#f6871f", textTransform: "uppercase"},
      h2: {fontFamily: "Industry"},
      h3: {fontFamily: "Industry"},
      h4: {fontFamily: "Industry"},
      h5: {fontFamily: "Industry"},
      h6: {fontFamily: "Industry"},
      body1: {fontFamily: "Open Sans"},
      body2: {fontFamily: "Open Sans"},
      caption: {fontFamily: "Open Sans"},
      subtitle1: {fontFamily: "Open Sans"},
      subtitle2: {fontFamily: "Open Sans"}
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {main: "#f6871f", contrastText: "#fef2e7"},
      secondary: {main: "#7eba40", contrastText: "#f2f8ec"},
      text: {primary: "#000000", secondary: "#A6A6A6"},
      background: {
        default: white,
        paper: white
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Industry",
              src: "local('Industry Ultra'), local('Industry-Ultra'), url('/providers/nz-institute-of-sport/Industry-Ultra.woff2') format('woff2')",
              fontWeight: "normal",
              fontStyle: "normal"
            }
          ]
        }
      }
    }
  },
  nzma: {
    typography: {
      fontFamily: "OakesGrotesk"
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {
        main: "#ea496a"
      },
      secondary: {
        main: "#0407f2"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "OakesGrotesk",
              src: "url('/providers/nzma/OakesGrotesk-Regular.woff2') format('woff2')",
              fontWeight: "normal",
              fontStyle: "normal"
            }
          ]
        }
      },
      MuiButton: {
        root: {
          borderRadius: "0.75rem",
          fontWeight: 700,
          textTransform: "none"
        }
      }
    }
  },
  yoobee: {
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {
        main: "#bd89ff"
      }
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "0.75rem",
          fontWeight: 500
        }
      }
    }
  },
  "up-online": {
    palette: {
      ...defaultPalette,
      primary: {
        main: "#f97a62"
      },
      secondary: {
        main: "#006990"
      }
    }
  },
  nzst: {
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {
        main: "#f26729"
      }
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "0.75rem",
          fontWeight: 500
        }
      }
    }
  },
  colab: {
    typography: {
      fontFamily: "Circular",
      h1: {fontWeight: "bold"},
      h2: {fontWeight: "bold"},
      h3: {fontWeight: "bold"},
      p: {fontWeight: "bold"}
    },
    palette: {
      ...defaultPalette,
      type: "light",
      primary: {
        main: "#000000"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Circular",
              src: 'url("/providers/colab/CircularXXWeb-Book.woff2") format("woff2")',
              fontWeight: 500
            }
          ]
        }
      },
      MuiButton: {
        root: {
          borderRadius: "0px",
          fontWeight: "bold"
        },
        containedPrimary: {
          backgroundColor: "#13ad85",
          "&:hover": {
            backgroundColor: darken("#13ad85", 0.25)
          }
        }
      }
    }
  }
};

export {rootTheme};
export function createProviderTheme(provider) {
  console.debug("Creating theme for " + provider);
  const theme = responsiveFontSizes(createTheme(merge(rootDefaults, themeByProvider[provider]))),
    {
      palette: {
        primary: {main},
        secondary: {light}
      }
    } = theme;
  const themeCalculatedOverrides = merge(theme, {
    palette: {
      action: {
        hover: lighten(light, 0.5)
      }
    },
    overrides: {
      MuiTypography: {
        root: {
          "& ul": {
            listStyle: "none",
            "& li::before": {
              content: '"\\2022"',
              color: main,
              fontWeight: "bold",
              display: "inline-block",
              width: "1em",
              marginLeft: "-1em",
              fontSize: "2em",
              verticalAlign: "middle"
            }
          }
        }
      }
    }
  });
  console.debug("theme", theme);
  return themeCalculatedOverrides;
}

/**
 * a style function to map a Material theme to styles for simple markup (headings etc)
 * @param {*} theme 
 * @returns 
 */
export function themedMarkup(theme) {
  return {
    root: {
      ...theme.typography.body1,
      "& h1": {...theme.typography.h1},
      "& h2": {...theme.typography.h2},
      "& h3": {...theme.typography.h3},
      "& h4": {...theme.typography.h4},
      "& p": {...theme.typography.body1},
      "& ol.alpha,&amp; ol ol": {
        listStyle: "lower-alpha",
        "&amp; li:before": {
          content: "none"
        }
      },
      "& ol.roman>li, &amp; ol ol ol": {
        listStyle: "lower-roman"
      },
      "& .image": {
        height: "10em",
        objectFit: "cover",
        objectPosition: "0% 40%",
        width: "100%"
      },
      "& .collapse": {
        transition: "transform 1s"
      },
      "& .sectionHeading": {
        "&:hover": {
          color: theme.palette.primary.light
        }
      }
    }
  };
}