import PropTypes from "prop-types";
import React, {useState} from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Button, IconButton, Menu, MenuItem, TableCell, Typography, withStyles} from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import {ReactComponent as Sort} from "../../icons/Sort.svg";
import clsx from "clsx";
import {darken} from "@material-ui/core/styles/colorManipulator";

/**
 * A table component for a few of screens full of preloaded rows
 * @param {*} param0
 */
const SimpleTable = ({
  classes,
  className,
  itemName = "item",
  title = `${itemName}s`,
  emptyLabel = `No ${itemName}s found`,
  moreLabel = `Load More ${itemName}s`,
  rows,
  pageSize = Number.MAX_SAFE_INTEGER,
  sort,
  defaultSortColumn = null,
  defaultSortDescending = false,
  renderHeaderRow,
  renderRow,
  getCellProps = (column) => ({})
}) => {
  const hasRows = renderRow && Array.isArray(rows) && rows.length > 0;
  const [pagesDisplayed, setPagesDisplayed] = useState(1);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDescending, setSortDescending] = useState(defaultSortDescending);
  const compare = sortColumn && sort[sortColumn];
  const sortedRows = hasRows && (compare ? [...rows].sort((a, b) => (sortDescending ? compare(b, a) : compare(a, b))) : rows);
  return (
    <TableContainer className={clsx(classes.root, className)}>
      <Table className={classes.table}>
        {title &&
          (renderHeaderRow ? (
            <TableHead>
              <TableRow className={classes.header}>
                {renderHeaderRow(title).map((column, i) => (
                  <TableCell key={i} {...getCellProps(i)}>
                    {column}
                    {sort && !!sort[column] && (
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          setSortColumn(column);
                          setSortDescending(!sortDescending);
                        }}
                      >
                        {sortColumn === column ? sortDescending ? <KeyboardArrowDown /> : <KeyboardArrowUp /> : <Sort />}
                      </IconButton>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : (
            <caption className={classes.captionHeader}>
              <Typography display="inline" className={classes.title} variant="h6">
                {title}
              </Typography>
              {sort && rows && rows.length > 1 && (
                <Sort
                  style={{float: "right"}}
                  onClick={(event) => {
                    setSortMenuAnchor(event.currentTarget);
                  }}
                />
              )}
            </caption>
          ))}
        {hasRows && (
          <TableBody>
            {sortedRows.slice(0, pagesDisplayed * pageSize).map((row, i) => (
              <TableRow key={i} className={classes.row}>
                {renderRow &&
                  renderRow(row).map((cell, i) => (
                    <TableCell className={classes.cell} key={i} {...getCellProps(i)}>
                      {cell}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        )}
        <caption className={classes.caption}>
          {!hasRows && (
            <Typography color="error" variant="body1">
              {emptyLabel}
            </Typography>
          )}
          {hasRows && pagesDisplayed * pageSize < sortedRows.length && (
            <Button variant="text" color="primary" className={classes.more} onClick={() => setPagesDisplayed(pagesDisplayed + 1)}>
              {moreLabel}
            </Button>
          )}
        </caption>
      </Table>
      {sort && (
        <Menu
          id="sort-menu"
          anchorEl={sortMenuAnchor}
          keepMounted
          open={Boolean(sortMenuAnchor)}
          onClose={() => setSortMenuAnchor(null)}
          elevation={1}
          variant="menu"
        >
          {Object.entries(sort).map(([label], i) => {
            const isSelected = sortColumn === label;
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  setSortColumn(label);
                  setSortDescending(isSelected && !sortDescending);
                  setSortMenuAnchor(null);
                }}
                selected={isSelected}
              >
                {label}
                {isSelected && (sortDescending ? <KeyboardArrowDown /> : <KeyboardArrowUp />)}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </TableContainer>
  );
};

SimpleTable.propTypes = {
  defaultSortColumn: PropTypes.string,
  defaultSortDescending: PropTypes.bool,
  emptyLabel: PropTypes.elementType, // String to display when rows is empty
  moreLabel: PropTypes.elementType, // Label for 'load more' button
  pageSize: PropTypes.any, // Number of rows to be displayed with each load more
  renderHeaderRow: PropTypes.func, // ()=>return array of components for header
  renderRow: PropTypes.func, // ([rows])=>return array of components for each row of data
  rows: PropTypes.array, // Data to display
  title: PropTypes.element, // Title for table
  itemName: PropTypes.string, // singular noun for items in table
  getCellProps: PropTypes.func, // (column)=>return props for table cell
  sort: PropTypes.object // map column name+>sort function
};

export default withStyles(
  (theme) => ({
    header: {},
    table: {
      borderCollapse: "collapse"
    },
    title: {
      textTransform: "uppercase"
    },
    captionHeader: {
      captionSide: "top !important"
    },
    more: {
      textTransform: "none"
    },
    cell: {
      borderColor: theme.palette.background.default,
      borderBottom: "none"
    },
    row: {
      "&:nth-of-type(odd)": {
        backgroundColor: darken(theme.palette.background.paper, 0.1)
      },
      "&:nth-of-type(even)": {
        backgroundColor: darken(theme.palette.background.paper, 0.05)
      }
    },
    caption: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center !important"
      }
    }
  }),
  {name: "UpSimpleTable"}
)(SimpleTable);
