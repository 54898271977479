/**
 * Up utility api to check phone
 */

// Not configurable!
const url = "https://acgpublicapi.azurewebsites.net/public/util/phone";

export async function checkPhone(number, country) {
  return fetch(`${url}/${(number || "").replaceAll(/\D/g, "") || "0"}/${country || "NZ"}`).then((result) => result.json());
}
