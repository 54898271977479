import {Box, Typography} from "@material-ui/core";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {SectionHeading} from "up-form";
import Submissions from "./Submissions";
import {Message} from "up-form";
import {useEnrolments} from "up-state";
import {useSelector} from "react-redux";
import {selectUser} from "../../state/appReducers";
import {Optional} from "up-form";

export default function GradesPage() {
  const {t} = useTranslation();
  const {studentId} = useSelector(selectUser) || {};
  const {data: enrolments, error} = useEnrolments(studentId);

  return (
    <Box p={1}>
      <Typography variant="h1">{t("GradesPage.title")}</Typography>
      <Typography paragraph variant="body1">
        {t("GradesPage.intro")}
      </Typography>
      <Submissions enrolments={enrolments} />
      <Optional name="GradesPage.pleaseNote">
        <SectionHeading title={t("GradesPage.footnote.title")} />
        <Typography gutterBottom variant="body1">
          <Trans t={t} i18nKey="GradesPage.footnote.text" />
        </Typography>
      </Optional>
      {error && <Message variant="error" message={error.message} open />}
    </Box>
  );
}
