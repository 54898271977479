import {createAsyncAction, asyncReducer} from "redux-promise-middleware-actions";

import upApi from "up-api";

var config = {
  context: {
    providerId: null,
    opportunityId: null
  }
};

// for all async actions we just aet the metadata to the input params for tracking purposes
function identity(params) {
  return params;
}
/**
 * Update session id for later resume given a sessionId. If it has no
 * sessionId, *remove* existing session as it's stale
 * @param sessionId
 */
export function updateUpSessionId(sessionId) {
  if (sessionId) sessionStorage.setItem("up.sessionId", sessionId);
  else sessionStorage.removeItem("up.sessionId");
}
/**
 * Update session id for later resume given the application payload. If it has no
 * sessionId, *remove* existing session as it's stale
 * @param application
 * @returns
 */
function updateUpSession(application) {
  const {opportunity: {sessionId} = {}} = application;
  updateUpSessionId(sessionId);
  return application;
}

export function init(newConfig) {
  config = {...config, ...newConfig};
}

export const application = createAsyncAction("Up-Api/APPLICATION", ({opportunityId}) =>
  upApi.application(opportunityId || config.context.opportunityId).then(updateUpSession)
);

export const courses = createAsyncAction("Up-Api/COURSES", ({provider}) => upApi.courses(provider || config.context.providerId));
export const createApplication = createAsyncAction("Up-Api/CREATEAPPLICATION", (params) =>
  upApi.createApplication(params).then(updateUpSession)
);
export const createEmergencyContact = createAsyncAction("Up-Api/CREATEEMERGENCYCONTACT", (params) => upApi.createContact(params));
export const createCase = createAsyncAction("Up-Api/CREATECASE", (params) => upApi.createCase(params));
export const createEmailCase = createAsyncAction("Up-Api/CREATEEMAILCASE", (params) => upApi.createEmailCase(params));
export const createLead = createAsyncAction(
  "Up-Api/CREATELEAD",
  (params) =>
    // Include contextual GoogleAnalytics client id, if present
    upApi.createLead({
      googleAnalyticsClientId: (document.cookie.match(/(?:^|;)\s*_ga=([^;]+)/) || [, ""])[1],
      ...params
    }),
  identity
);
export const createParentOrGuardian = createAsyncAction(
  "Up-Api/CREATEPARENTORGUARDIAN",
  (params) => upApi.createContact(params),
  identity
);

export const iwi = createAsyncAction("IWI", () => upApi.iwi());

export const enrolments = createAsyncAction(
  "Up-Api/ENROLMENTS",
  ({studentId, enrolmentId}) => upApi.enrolments(studentId, enrolmentId),
  identity
);

export const files = createAsyncAction("Up-Api/FILES", ({studentId}) => upApi.studentFiles(studentId), identity);

export const metadata = createAsyncAction("Up-Api/METADATA", () => upApi.metadata());
export const payments = createAsyncAction("Up-Api/PAYMENTS", ({studentId}) => upApi.payments(studentId), identity);

export const pricing = createAsyncAction(
  "Up-Api/PRODUCTPRICING",
  ({productId, provider}) => upApi.productPricing(productId, provider),
  identity
);

export const applicationPricing = createAsyncAction(
  "Up-Api/APPLICATIONPRICING",
  ({opportunityId, provider}) => upApi.applicationPricing(opportunityId, provider),
  identity
);
export const invoicePricing = createAsyncAction("INVOICEPRICING", ({invoiceId}) => upApi.invoicePricing(invoiceId), identity);

export const updateApplication = createAsyncAction(
  "Up-Api/UPDATEAPPLICATION",
  ({opportunityId, body}) => upApi.updateApplication(opportunityId, body).then(updateUpSession),
  identity
);

export const supportingDocuments = createAsyncAction(
  "Up-Api/SUPPORTINGDOCUMENTS",
  (files, id) => upApi.supportingDocuments(files, id),
  identity
);

export const profile = createAsyncAction("PROFILE", ({studentId}) => upApi.student(studentId), identity);
export const updateProfile = createAsyncAction("UPDATEPROFILE", (profile) => upApi.updateStudent(profile), identity);

export const studentImage = createAsyncAction(
  "Up-Api/STUDENTIMAGE",
  ({studentId}) =>
    upApi
      .studentImage(studentId)
      .then((blob) => window.URL.createObjectURL(blob))
      .catch(() => null), // No problem, no image available
  identity
);
export const updateStudentImage = createAsyncAction(
  "Up-Api/UPDATESTUDENTIMAGE",
  (studentId, imageBlob) => upApi.updateStudentImage(studentId, imageBlob),
  identity
);

export const createOrder = createAsyncAction(
  "Up-Api/CREATEORDER",
  (opportunityId) => {
    return upApi.createOrder(opportunityId);
  },
  identity
);
export const createDebitSuccessAccount = createAsyncAction(
  "Up-Api/CREATEDEBITSUCCESSACCOUNT",
  (params) => {
    return upApi.createDebitSuccessAccount(params);
  },
  identity
);
export const createPaymentSession = createAsyncAction(
  "Up-Api/CREATEPAYMENTSESSION",
  (opportunityId, body) => {
    return upApi.createStripeSession(opportunityId, body);
  },
  identity
);

export const applicationSession = createAsyncAction(
  "Up-Api/APPLICATIONSESSION",
  ({sessionId}) => {
    return upApi.session(sessionId);
  },
  identity
);

export const getWorkplaceEducators = createAsyncAction(
  "Up-Api/GETWORKPLACEEDUCATORS", 
  () => upApi.getWorkplaceEducators());

export const getWorkplaceEmployers = createAsyncAction(
  "Up-Api/GETWORKPLACEEMPLOYERS",
  ({accountId}) => upApi.getWorkplaceEmployers(accountId));

export const createWorkplaceEmployer = createAsyncAction(
  "Up-Api/CREATEWORKPLACEEMPLOYER", 
  (body) => {
    return upApi.createWorkplaceEmployer(body);
  },
  identity
);

export const createZipCheckoutSession = createAsyncAction(
  "Up-Api/CREATEZIPCHECKOUTSESSION",
  (opportunityId, body) => {
    return upApi.createZipCheckout(opportunityId, body);
  },
  identity
);

export const createZipCharge = createAsyncAction(
  "Up-Api/CREATEZIPCHARGE",
  (opportunityId, body) => {
    return upApi.createZipCharge(opportunityId, body);
  },
  identity
);

export const createStripeIntentAndSubscription = createAsyncAction(
  "Up-Api/CREATESTRIPEINTENTANDSUBSCRIPTION",
  (body) => {
    return upApi.createStripeIntentAndSubscription(body);
  },
  identity
);

export const upApiReducers = {
  profile: asyncReducer(profile),
  updateProfile: asyncReducer(updateProfile),
  studentImage: asyncReducer(studentImage),
  updateStudentImage: asyncReducer(updateStudentImage),
  courses: asyncReducer(courses),
  enrolments: asyncReducer(enrolments),
  files: asyncReducer(files),
  metadata: asyncReducer(metadata),
  payments: asyncReducer(payments),
  pricing: asyncReducer(pricing),
  applicationPricing: asyncReducer(applicationPricing),
  invoicePricing: asyncReducer(invoicePricing),
  createCase: asyncReducer(createCase),
  createLead: asyncReducer(createLead),
  application: (state, action) =>
    [createApplication, updateApplication, application]
      .map((creator) => asyncReducer(creator))
      .reduce((state, reducer) => reducer(state, action), state),
  createEmergencyContact: asyncReducer(createEmergencyContact),
  createParentOrGuardian: asyncReducer(createParentOrGuardian),
  createOrder: asyncReducer(createOrder),
  createPaymentSession: asyncReducer(createPaymentSession),
  createDebitSuccessAccount: asyncReducer(createDebitSuccessAccount),
  supportingDocuments: asyncReducer(supportingDocuments),
  applicationSession: asyncReducer(applicationSession),
  getWorkplaceEducators: asyncReducer(getWorkplaceEducators),
  getWorkplaceEmployers: asyncReducer(getWorkplaceEmployers),
  createWorkplaceEmployer: asyncReducer(createWorkplaceEmployer),
  createZipCheckoutSession: asyncReducer(createZipCheckoutSession),
  createZipCharge: asyncReducer(createZipCharge),
  createStripeIntentAndSubscription: asyncReducer(createStripeIntentAndSubscription)
};
