import React, {useEffect} from "react";
import {Message} from "up-form";
import authenticator from "../../actions/Authenticator";
export const SignInPage = ({forwardTo, children}) => {
  useEffect(() => {
    if (!authenticator.isLoggedIn && !authenticator.isRedirecting)
      // not alreaydy redirecting or logged in
      authenticator.signIn({forwardTo});
  }, [forwardTo]);
  return (
    <>
      <Message
        open={!authenticator.isLoggedIn}
        variant="busy"
        message="Signing In..."
      />
      {children}
    </>
  );
};
