/**
 * App entry point, dispatch to each sub app
 */
import {LinearProgress} from "@material-ui/core";
import {createBrowserHistory} from "history";
import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import React, {Suspense} from "react";
import {initReactI18next} from "react-i18next";
import {Provider} from "react-redux";
import {init as analyticsInit, trackerMiddleware} from "up-analytics";
import UpApi from "up-api";
import {GlobalProvider} from "up-form";
import {createStore} from "up-state";
import config, {isDev} from "../../config";
import {createLogger} from "redux-logger";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ProviderPage from "./ProviderPage";
import authenticator, {authenticatorInit} from "../../actions/Authenticator";
import appReducers, {changeUser} from "../../state/appReducers";

// Pull a bunch of config settings for this  env
const {
  analytics: {appName, instrumentationKey, trackEvents},
  global: {
    options: {persist, mockUser},
    links: {forwardLogin: defaultLoggedInPage}
  },
  amplify,
  msal,
  upApi: {baseUri}
} = config;

const {store} = createStore(
  {
    key: "student-portal",
    whitelist: persist ? ["createLead", "application"] : []
  },
  {
    middleware: [trackerMiddleware(trackEvents), isDev && createLogger({diff: true})]
  },
  {app: appReducers}
);
// Use custom history so we can listen for router page events for analytics
const appHistory = createBrowserHistory();
analyticsInit({appName, history: appHistory, instrumentationKey});

authenticatorInit({
  ...(!mockUser && {
    msalConfig: msal,
    amplifyConfig: amplify
  }),
  handleLogin: (_authenticator, {forwardTo}, user) => {
    store.dispatch(changeUser(user));
    appHistory.push(forwardTo || defaultLoggedInPage);
  }
});

UpApi.configure({
  baseUri,
  headers: {source: "portal"},
  scaffold: {
    //TODO: testing, remove!!!!
    get: (uri) => {
      if (mockUser) {
        if (/v1\/secure\/student\/.*\/file.*/.test(uri)) return "/dummy/files.json";
        else if (/v1\/secure\/enrolment.*/.test(uri)) return "/dummy/enrolment.json";
        else if (/v1\/secure\/payment.*/.test(uri)) return "/dummy/payments.json";
        else if (/v1\/secure\/student.*/.test(uri)) return "/dummy/student.json";
      }
    }
  },
  accessToken: async () => {
    const {accessToken} = await authenticator.fetchTokens();
    return accessToken;
  }
});

i18n
  .use(HttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const App = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <GlobalProvider {...config.global}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ProviderPage history={appHistory} />
          </MuiPickersUtilsProvider>
        </Provider>
      </GlobalProvider>
    </Suspense>
  );
};

export default App;
