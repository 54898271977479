/**
 * Content component for support form
 */
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ExternalLink from "../../content/ExternalLink";
import {useGlobal} from "up-form";
export default function SupportContent({renderForm: SupportForm, ...other}) {
  const {
    links: {techSupport, studentSupport, counsellingSupport, counsellingLink}
  } = useGlobal();
  const {t} = useTranslation();
  return (
    <>
      <h1>My Support</h1>
      <p>
        Everyone needs some help sometimes, that's why we offer different types of support at {t("Provider.shortName")} Online. We
        are here to help you succeed.
      </p>
      <h2>Getting Started</h2>
      <p>
        Before your programme of learning begins, you will have completed our introduction to online learning:{" "}
        <b>Ready, Set, Go!</b>
      </p>
      <p>
        <b>Ready, Set, Go!</b> gives you the information you need to succeed in the Online campus. You will learn what it means to
        study online at&nbsp;{t("Provider.shortName")}, you'll get information about how to be a successful online learner, we'll
        show you the features of&nbsp;{t("Provider.shortName")} Online, and give you tips and tricks to manage your time and
        motivation while you learn from home.
      </p>
      <p>
        <b>Ready, Set, Go!</b> Is an important first step of your online learning, please take the time to read over and get
        involved with the fun activities.
      </p>
      <h2>Academic Support</h2>
      <p>
        Your tutor can help you to understand the programme materials, answer questions about your assessments, and help keep you on
        track with your learning. They can help you with:
        <ul>
          <li>content support</li>
          <li>general course issues</li>
          <li>assessment support, extensions, progress</li>
          <li>understanding assessment feedback</li>
        </ul>
        Simply email or send a message to your online tutor for help. Meeting regularly with your tutor will help you stay motivated
        and on track with your learning.
      </p>
      <h2>How to book a one-on-one video session with your tutor</h2>
      <p>
        If you would like to chat to your tutor about any aspect of your study, you can email them or send them a message through
        &nbsp;{t("Provider.shortName")} Online messaging.
      </p>
      <h2>Online Learning Support</h2>
      <p>
        Online Learning Support can help you with areas of your learning that are not related to the programme content. This might
        include learning support (strategies to study, support tools to use) but also extend to areas such as pastoral care, taking
        a break from studies, StudyLink problems, visa questions, changing personal details, and many more. Please send an email
        to&nbsp;
        <ExternalLink href={studentSupport} />
      </p>
      <h2>Learning Differences</h2>
      <p>
        Let your tutor or Online Learning Support know if you identify as neurodiverse, are differently abled, or if you have any
        learning barriers or difficulties. We can provide extra support or resources for learning and achievement. Together we will
        work through an individual plan to assist you.
      </p>
      <h2>Your Peers</h2>
      <p>
        You are not alone in your learning journey. You have the online campus community at your fingertips. The other students in
        the programme are studying the same material as you and may have the answers to your questions. Your peers can be reached by
        sending a message through&nbsp;{t("Provider.shortName")} Online messaging or responding to forum threads and journal
        entries.
      </p>
      <h2>IT support</h2>
      <p>
        Sometimes technology isn't playing on your team. If you have any technical issues with&nbsp;{t("Provider.shortName")}
        Online, please send an email to&nbsp;
        <ExternalLink href={techSupport} />
      </p>
      <p>
        Include as much information as possible: In addition to describing the issue, include what browser you are using, any error
        messages popping up, and screenshots of the issue. You can also tell them what you have already tried to fix the problem.
        This information will help the online support team to identify the problem and provide the appropriate support.
      </p>
      <h2>Career support </h2>
      <p>
        Our Career and Employment navigators will help you with valuable job-hunting skills, like CV writing and interview
        techniques through Live Sessions in&nbsp;{t("Provider.shortName")} Online. Don't worry if you can't attend, these will be
        recorded.
      </p>
      <h2>Extra support</h2>
      <p>If you need some extra support during your studies, you can access FREE counselling sessions.</p>
      <p>Australian Counselling Service (ACS) can deliver free counselling sessions from anywhere in New Zealand.</p>
      <p>
        ACS (Australian Counselling Service) provides counselling support for a range of concerns including stress, relationships,
        managing your studies and everyday worries. ACS is the clinical counselling division of the Australian Institute of
        Professional Counsellors (AIPC). ACS's mission is to make quality mental health care services available to everyone.
      </p>
      <p>
        Book your free online session now by registering at &nbsp;
        <ExternalLink href={counsellingLink} />
        &nbsp;or emailing&nbsp;
        <ExternalLink href={counsellingSupport} />
      </p>
      <p>
        <ExternalLink href="/providers/nzma/ACS support.pdf">More Info</ExternalLink>
      </p>
    </>
  );
}

SupportContent.propTypes = {
  renderForm: PropTypes.func
};
