import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Message, SectionHeading, useGlobal} from "up-form";
import {useEnrolments} from "up-state";
import {selectUser} from "../../state/appReducers";
import SkeletonGrid from "../SkeletonGrid";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "normal",
    fontSize: "1em"
  },
  link: {
    whiteSpace: "normal"
  },
  avatar: {}
}));

const CourseCard = ({
  profile: {studentUPId} = {},
  enrolment: {courseName, courseCode, percentComplete}
}) => {
  const classes = useStyles(),
    {
      links: {lmsCourse}
    } = useGlobal();
  return (
    <Card variant="elevation">
      <CardHeader
        classes={{title: classes.title, avatar: classes.avatar}}
        avatar={
          <CheckCircle
            color={percentComplete === 100 ? "primary" : "disabled"}
          />
        }
        title={
          <Link
            className={classes.link}
            color="textPrimary"
            href={`${lmsCourse}/?course=${courseCode}&upId=${studentUPId}`}
          >
            {courseName}
          </Link>
        }
        subheader={courseCode}
        action={
          percentComplete && (
            <CircularProgress variant="determinate" value={percentComplete} />
          )
        }
      />
    </Card>
  );
};

export const Courses = ({profile}) => {
  const {studentId} = useSelector(selectUser) || {};
  const {data: enrolments, pending, error} = useEnrolments(studentId);

  const {t} = useTranslation();
  const {preview} = useGlobal();

  return (
    <>
      <SectionHeading title={t("ProfileForm.courses.title")} />
      {preview || pending ? (
        <SkeletonGrid rows={3} />
      ) : (
        Array.isArray(enrolments) && (
          <>
            <Box m={2} mb={3}>
              <Grid container direction="column" spacing={2}>
                {enrolments.map((enrolment, i) => (
                  <Grid key={i} item xs={12}>
                    <CourseCard enrolment={enrolment} profile={profile} />
                  </Grid>
                ))}
                {enrolments.length === 0 && (
                  <Typography variant="body1" color="error">
                    {t("ProfileForm.courses.none")}
                  </Typography>
                )}
              </Grid>
            </Box>
          </>
        )
      )}
      {error && (
        <Message
          open={!!error}
          variant="error"
          title={t("ProfileForm.courses.error")}
          message={error.message}
        />
      )}
    </>
  );
};

Courses.propTypes = {
  profile: PropTypes.any
};
