import {Button, CircularProgress} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import authenticator from "../../actions/Authenticator";
import SignIn from "../icons/SignIn";
const SignInButton = ({busy}) => {
  const {t} = useTranslation();
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        endIcon={
          busy ? <CircularProgress color="secondary" size="1em" /> : <SignIn />
        }
        onClick={() => authenticator.signIn()} // Sign in,default desination
      >
        {t("Login.label")}
      </Button>
    </>
  );
};

export default SignInButton;
