import {toLabel, fromLabel, fromId, toId} from "up-form";

/**
 * ProfileForm->student API payload
 * @param {*} param0
 * @param {*} param1
 */
export function toStudent(
  {studentId},
  {
    buildingPropertyName,
    city,
    country,
    emailAddress,
    facebook,
    firstName,
    flatUnitDetails,
    homePhone,
    lastName,
    linkedin,
    mobilePhone,
    personalSummary,
    preferredName,
    postcode,
    state,
    stateName,
    streetName,
    streetOrLotNumber,
    suburb,
    twitter
  }
) {
  function toFullUrl(prefix, value) {
    return value && value.replace(/(?:.*\/)?([^/]+\/?)$/, `${prefix}/$1`);
  }
  return {
    buildingPropertyName,
    city,
    country: toLabel(country),
    countryId: toId(country),
    emailAddress,
    facebookURL: toFullUrl("https://facebook.com", facebook),
    firstName,
    flatUnitDetails,
    homePhone,
    lastName,
    linkedinURL: toFullUrl("https://linkedin.com/in", linkedin),
    mobilePhone,
    personalSummary,
    postcode,
    preferredName,
    state: stateName || (state && toLabel(state)),
    stateId: state && toId(state),
    streetNumberAndName: streetName,
    streetOrLotNumber,
    studentUPId: studentId,
    suburb,
    twitterURL: toFullUrl("https://twitter.com", twitter)
  };
}

/**
 * Map form fields to student API
 */
export function toForm(
  {
    buildingPropertyName,
    city,
    country,
    countryId,
    emailAddress,
    facebookURL,
    firstName,
    flatUnitDetails,
    homePhone,
    lastName,
    linkedinURL,
    mobilePhone,
    postcode,
    personalSummary,
    preferredName,
    state,
    stateId,
    streetNumberAndName,
    streetOrLotNumber,
    suburb,
    twitterURL
  },
  metadata
) {
  function trimToHandle(url) {
    return url && url.replace(/.*\/(?=.)/, "");
  }
  return {
    buildingPropertyName,
    country: countryId ? fromId(metadata.countries, countryId) : country && fromLabel(metadata.countries, country),
    city,
    emailAddress,
    firstName,
    facebook: trimToHandle(facebookURL),
    flatUnitDetails,
    homePhone,
    lastName,
    linkedin: trimToHandle(linkedinURL),
    mobilePhone,
    personalSummary,
    postcode,
    preferredName,
    state: stateId && fromId(metadata.states, stateId),
    stateName: state,
    streetOrLotNumber,
    streetName: streetNumberAndName, // note mismatch here - SUT started seperating
    suburb,
    twitter: trimToHandle(twitterURL)
  };
}
